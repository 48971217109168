import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Paper, { PaperProps } from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";

export interface ChartProps {
  PaperProps?: PaperProps;
}

export interface ChartContentProps {
  showNoDataState?: boolean;
  showLoading?: boolean;
  noDataStateText?: React.ReactNode;
  height?: number | string;
}

/**
 * A chart which is displayed on the chart page. The children are rendered within the
 * `<ChartContent>` component. Unless otherwise specified, the height of the chart is 40 spacing
 * units.
 */
export const Chart: React.FunctionComponent<
  React.PropsWithChildren<ChartProps>
> = ({ PaperProps, children }) => <Paper {...PaperProps}>{children}</Paper>;

/**
 * Content (i.e., non-title) part of a chart. Place inside a Chart component to indicate the
 * content area. By default the content will always have a height of 48 spacing units but this can
 * be overridden via the height prop.
 */
export const ChartContent: React.FunctionComponent<
  React.PropsWithChildren<ChartContentProps>
> = ({
  showNoDataState = false,
  noDataStateText = "No data",
  showLoading = false,
  height,
  children,
}) => {
  const theme = useTheme();

  return (
    <Box position="relative">
      <ChartLoadingIndicator show={showLoading} />
      <Fade in={showNoDataState}>
        <Box
          position="absolute"
          left={0}
          right={0}
          top={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body1" color="textSecondary">
            {noDataStateText}
          </Typography>
        </Box>
      </Fade>
      <Fade in={!showLoading && !showNoDataState}>
        <Box
          height={height || theme.spacing(48)}
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </Fade>
    </Box>
  );
};

// Internal component for loading indicator.

interface ChartLoadingIndicatorProps {
  show: boolean;

  CircularProgressProps?: CircularProgressProps;
}

const ChartLoadingIndicator: React.FunctionComponent<
  React.PropsWithChildren<ChartLoadingIndicatorProps>
> = ({ show, CircularProgressProps }) => (
  <Box
    position="absolute"
    left={0}
    right={0}
    top={0}
    bottom={0}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Fade in={show}>
      <CircularProgress {...CircularProgressProps} />
    </Fade>
  </Box>
);

/**
 * Title of chart. Place inside a Chart component to title the chart.
 */
export const ChartTitle: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => (
  <>
    <Box p={2}>
      <Typography variant="body1" component="h1">
        <Box component="span" fontWeight="fontWeightMedium">
          {children}
        </Box>
      </Typography>
    </Box>
    <Divider />
  </>
);

/**
 * Caption of chart. Place inside a ChartCaption component to provide a caption for the char.
 */
export const ChartCaption: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => (
  <>
    <Divider />
    <Box px={2} pt={1} pb={2}>
      <Typography variant="caption" color="textSecondary">
        {children}
      </Typography>
    </Box>
  </>
);
