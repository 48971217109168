import * as React from "react";

import List from "@mui/material/List";

export interface INavigationPanelSectionProps {
  /** Renders as `data-role` on the component */
  dataRole: string;
}

/**
 * A component that creates a section in the navigation panel.
 * It is designed to be used as a child component of the `NavigationPanel` and `NavigationPanelFooter` components.
 */
/**
 * A component that groups a set of navigation links in the navigation panel.
 * It is designed to be used as a child component of the `NavigationPanel` component.
 */
export const NavigationPanelSection: React.FunctionComponent<
  React.PropsWithChildren<INavigationPanelSectionProps>
> = ({ dataRole, children }) => {
  return (
    <List data-role={dataRole} component="nav">
      {children}
    </List>
  );
};
export default NavigationPanelSection;
