import ListItemButton from "@mui/material/ListItemButton";
import ListItemText, {
  type ListItemTextProps,
} from "@mui/material/ListItemText";
import * as React from "react";
import { Link, type LinkProps } from "react-router";

export interface INavigationPanelSectionLinkProps {
  /** The route to the new location */
  to: LinkProps["to"];
  /** The link's text */
  text: ListItemTextProps["primary"];
  /** Whether of not the link is in selected mode */
  selected: boolean;
  /** Renders as `data-role` on the component */
  dataRole: string;
}

/**
 * A component that renders a clickable link for internal navigation to another page in the application.
 * Wraps the `Link` component from the `react-router` library.
 * It is designed to be used as a child component of the `NavigationPanelSection` component.
 */
export const NavigationPanelSectionLink = ({
  to,
  selected,
  dataRole,
  text,
}: INavigationPanelSectionLinkProps) => {
  return (
    <ListItemButton
      component={Link}
      to={to}
      selected={selected}
      data-role={dataRole}
      // exclude the search params
      data-to={typeof to === "string" ? to : to.pathname}
      data-selected={selected.toString()}
    >
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export default NavigationPanelSectionLink;
