import * as React from "react";

import { type Theme } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ANNOTATION_INFO_LABELS,
  IBaseColumnDefinition,
  STATIC_COLUMNS_MAP,
  filterAnnotations,
} from "constants/columnDefinitions";
import { camelCase, fromPairs, kebabCase } from "lodash";
import {
  IApplication,
  IApplicationPatch,
  IApplicationPermissions,
  IApplicationQuery,
  IAssessmentScore,
  IAssessmentType,
  IDescription,
  IDescriptionsResponse,
  IPoolOutcomeCreate,
} from "../api";
import { ANNOTATION_INFO_LABEL_ENTRIES } from "../constants/annotationTypes";
import {
  ASSESSMENT_TYPE_INTERVIEW_1,
  ASSESSMENT_TYPE_INTERVIEW_2,
  ASSESSMENT_TYPE_INTERVIEW_3,
  ASSESSMENT_TYPE_INTERVIEW_4,
  ASSESSMENT_TYPE_INTERVIEW_5,
  ASSESSMENT_TYPE_INTERVIEW_6,
  ASSESSMENT_TYPE_INTERVIEW_AVERAGE,
} from "../constants/assessmentScoreTypes";
import { type OrderingState } from "../hooks/useSearchParams";
import CommentsInput from "./CommentsInput";
import DecisionStatusSelect from "./DecisionStatusSelect";
import InterviewAssessmentScoreInput from "./InterviewAssessmentScoreInput";
import PoolOutcomeAdmitYearInput from "./PoolOutcomeAdmitYearInput";
import PoolOutcomeSelect from "./PoolOutcomeSelect";
import PoolOutcomeSubjectSelect from "./PoolOutcomeSubjectSelect";

const styles = (theme: Theme) =>
  createStyles({
    table: {
      tableLayout: "fixed",
      "& thead tr th": {
        width: theme.spacing(8),
      },
      "& th.th-name, td.td-name": {
        paddingLeft: 0,
      },
      "& th.th-actions": {
        width: 50,
      },
      "& th.th-status": {
        width: 115,
      },
    },
    // A class to be applied to info chips that defines their margin and distinguishing colour.
    infoChips: fromPairs(
      ANNOTATION_INFO_LABEL_ENTRIES.map((entry: any) => [
        `& .info-${entry[0]}`,
        {
          marginRight: 2,
          marginBottom: 2,
          backgroundColor: entry[1].colour,
        },
      ]),
    ),
  });

// Properties common to both ApplicationsPageTable and ApplicationRow
interface ICommonProps {
  // A list of which columns render (select from a list provided by `generateAllColumns()`)
  columns: IColumnDefinition[];
  // The handler for view application detail event
  onViewApplication?: (application: IApplication) => void;
}

// The main component's properties
interface IProps extends ICommonProps, WithStyles<typeof styles> {
  // A page of applications to display.
  applications: IApplication[];
  // The state of the ordering of the applications.
  orderingState: Partial<OrderingState<string>>;
  // The handler for an ordering event
  onOrderChange: (ordering: OrderingState) => void;
}

/**
 * Defines a sub-column (used only when rendering a CSV download).
 *
 * The `render()` function will only be called to render the column data when generating a CSV
 * download, and will never be called to render the column data on-screen (as sub-columns are
 * never visible on-screen).
 */
type ISubColumnDefinition = IBaseColumnDefinition;

/**
 * Defines the mapping of the application data to a table column.
 *
 * The `render()` function will be called to render the column data both on-screen and when
 * generating a CSV download, unless a `renderForDownload()` function is provided in which case
 * the latter will be used when generating a CSV download.
 */
export interface IColumnDefinition extends IBaseColumnDefinition {
  /** the term to be used when ordering, if the column is sortable */
  ordering?: string;
  /** any additional query params to be applied when sorting */
  orderingQuery?: IApplicationQuery;
  /**
   * Defined when rendering a field for a CSV download is different from render().
   * If an array is returned then the field will be expanded into multiple columns in the CSV
   * download, and each entry in the array should be a column definition with just the `key`,
   * `title` and `render` properties.
   */
  renderForDownload?:
    | ((application: IApplication) => string)
    | ISubColumnDefinition[];
  /** visibility of the column, default is 'both' */
  visibility?: "download" | "ui" | "both";
}

/**
 * A custom download renderer for the applicant files link column
 */
const renderApplicantFilesForDownload = (application: IApplication) => {
  // If there is no external resources, render a blank column.
  if (
    !application.externalResources ||
    application.externalResources.length === 0
  ) {
    return "";
  }
  // Otherwise, render the URL.
  return application.externalResources[0].externalUrl;
};

const STATIC_COLUMNS: IColumnDefinition[] = [
  {
    ...STATIC_COLUMNS_MAP["name"],
    ordering: "candidateLastName,candidateForenames",
    visibility: "ui",
  },
  {
    ...STATIC_COLUMNS_MAP["forenames"],
    ordering: "candidateForenames",
    visibility: "download",
  },
  {
    ...STATIC_COLUMNS_MAP["lastname"],
    ordering: "candidateLastName",
    visibility: "download",
  },
  {
    ...STATIC_COLUMNS_MAP["pref-first-name"],
    ordering: "candidatePrefFirstName",
  },
  {
    ...STATIC_COLUMNS_MAP["subject"],
    ordering: "subjectDescription",
  },
  {
    ...STATIC_COLUMNS_MAP["academic-plan"],
    ordering: "subject",
  },
  {
    ...STATIC_COLUMNS_MAP["tags"],
    // array of sub-columns that the column is expanded into when downloading as CSV
    renderForDownload: ANNOTATION_INFO_LABEL_ENTRIES.map(
      (entry): ISubColumnDefinition => ({
        key: entry[0],
        title: entry[1].exportColumnNameOverride || entry[1].text,
        // regenerate annotations for each sub-column and select the value of the required
        // annotation, which is inefficient but fast enough give the number of annotations and
        // avoids a large code refactor.
        render: (application: IApplication) =>
          filterAnnotations(application).filter(
            (annotation) =>
              entry[1].text === ANNOTATION_INFO_LABELS[annotation.type.id].text,
          ).length > 0
            ? "Y"
            : "",
      }),
    ),
  },
  {
    ...STATIC_COLUMNS_MAP["college"],
    ordering: "collegePreferenceDescription",
  },
  STATIC_COLUMNS_MAP["gender"],
  {
    ...STATIC_COLUMNS_MAP["ucas"],
    ordering: "ucas_personal_id",
  },
  {
    ...STATIC_COLUMNS_MAP["usn"],
    ordering: "usn",
  },
  {
    ...STATIC_COLUMNS_MAP["application"],
    ordering: "camsisApplicationNumber",
  },
  {
    ...STATIC_COLUMNS_MAP["camsis-status"],
    ordering: "camsisStatusDescription",
  },
  STATIC_COLUMNS_MAP["subject-options"],
  {
    ...STATIC_COLUMNS_MAP["applicant-files"],
    renderForDownload: renderApplicantFilesForDownload,
  },
  STATIC_COLUMNS_MAP["pool-type"],
  STATIC_COLUMNS_MAP["pool-status"],
  {
    ...STATIC_COLUMNS_MAP["entry-year"],
    ordering: "admit_year",
  },
  {
    ...STATIC_COLUMNS_MAP["app-year"],
    ordering: "year",
  },
  STATIC_COLUMNS_MAP["interview-college-1"],
  STATIC_COLUMNS_MAP["interview-college-2"],
  STATIC_COLUMNS_MAP["predicted-grades"],
  {
    ...STATIC_COLUMNS_MAP["gcse-school-name"],
    ordering: "gcse_school_name",
  },
  {
    ...STATIC_COLUMNS_MAP["gcse-school-postcode"],
    ordering: "gcse_school_postcode",
  },
  {
    ...STATIC_COLUMNS_MAP["school-name"],
    ordering: "school_name",
  },
  STATIC_COLUMNS_MAP["school-type"],
  STATIC_COLUMNS_MAP["home-postcode"],
  STATIC_COLUMNS_MAP["birthdate"],
  {
    ...STATIC_COLUMNS_MAP["country-of-domicile"],
    ordering: "country_of_domicile",
  },
  {
    ...STATIC_COLUMNS_MAP["college-decision"],
    ordering: "college_decision",
  },
];

/** decides if the user can create/update a score */
const isScoreEditable = (
  permissions: IApplicationPermissions,
  score: IAssessmentScore | undefined,
): boolean =>
  // if the score doesn't exist - can the user create interview assessment scores?
  (score === undefined &&
    (permissions.createAssessmentScores ||
      permissions.createInterviewAssessmentScores)) ||
  // if the score exists - can the user update that score?
  (!!score && score.permissions.update);

/**
 * A dictionary of pool outcome descriptions indexed by their id.
 */
const POOL_OUTCOME_CHOICES = {
  ACCEPT: "Accepted",
  REJECT: "Rejected",
  HOLD: "On Hold",
  OFFER: "Offer",
  INTERVIEW: "Interview",
};

/**
 * Convert POOL_OUTCOME_CHOICES to a list of `IDescription` objects for the select component
 */
const POOL_OUTCOME_OPTIONS = Object.entries(POOL_OUTCOME_CHOICES).map(
  (entry) => ({ id: entry[0], description: entry[1] }),
);

/**
 * A custom download renderer for the pool outcome column
 */
const renderPoolOutcomeForDownload = (application: IApplication) => {
  return (
    (application.latestPoolOutcome &&
      application.latestPoolOutcome.status &&
      POOL_OUTCOME_CHOICES[application.latestPoolOutcome.status]) ||
    ""
  );
};

/**
 * A custom download renderer for the college change column
 */
const renderCollegeChangeForDownload = (application: IApplication) => {
  return (
    (application.latestPoolOutcome &&
      application.latestPoolOutcome.collegePreferenceDescription) ||
    ""
  );
};

/**
 * A custom download renderer for the subject change column
 */
const renderSubjectChangeForDownload = (application: IApplication) => {
  return (
    (application.latestPoolOutcome &&
      application.latestPoolOutcome.subjectDescription) ||
    ""
  );
};

/**
 * A custom download renderer for the entry year change column
 */
const renderEntryYearChangeForDownload = (application: IApplication) => {
  return (
    (application.latestPoolOutcome &&
      String(application.latestPoolOutcome.admitYear)) ||
    ""
  );
};

export const ASSESSMENT_TYPE_INTERVIEWS = new Set([
  ASSESSMENT_TYPE_INTERVIEW_1,
  ASSESSMENT_TYPE_INTERVIEW_2,
  ASSESSMENT_TYPE_INTERVIEW_3,
  ASSESSMENT_TYPE_INTERVIEW_4,
  ASSESSMENT_TYPE_INTERVIEW_5,
  ASSESSMENT_TYPE_INTERVIEW_6,
]);

/**
 * Render the average interview score. If the score comprises one or more zero scores, the score is
 * highlighted and given an explanatory tooltip.
 */
const renderAverageInterviewScore = (
  application: IApplication,
  score?: string | null,
) => {
  const zero_score = application.assessmentScores.find(
    (score) =>
      ASSESSMENT_TYPE_INTERVIEWS.has(score.typeId) && score.score === "0.00",
  );
  if (!zero_score) {
    return score;
  }
  return (
    <Tooltip title="This average comprises at least one zero interview score.">
      <Box
        component="span"
        bgcolor="#f48fb1"
        paddingLeft={0.5}
        paddingRight={0.5}
      >
        {score}
      </Box>
    </Tooltip>
  );
};

/**
 * Generates a list of all possible columns definitions to the consumer. Some column definitions
 * require `IDescriptionsResponse` data, `subjectsAndOptions` (the subject filter list) and the
 * `onUpdate` handler to complete their definition.
 */
export const generateAllColumns = (
  descriptions: IDescriptionsResponse | null,
  onUpdate: (patch: IApplicationPatch) => void,
  onPoolOutcomeCreate: (poolOutcome: IPoolOutcomeCreate) => void,
  subjectsAndOptions: IDescription[],
) => {
  // A renderer for the decision status column
  const renderStatus = (application: IApplication) => {
    // .. return just the decision if the user can't update the decision
    if (!application.permissions.createDecisions) {
      return (
        application.latestDecision && application.latestDecision.typeDescription
      );
    }
    return (
      <DecisionStatusSelect
        onUpdate={onUpdate}
        application={application}
        decisionTypes={descriptions && descriptions.decisionTypes}
      />
    );
  };

  // A renderer for the comments column
  const renderComment = (application: IApplication) => {
    // .. return just the comments if the user can't update the comments
    if (!application.permissions.updateComments) {
      return application.comments;
    }
    return <CommentsInput application={application} onUpdate={onUpdate} />;
  };

  // A custom renderer for the pool outcome column
  const renderPoolOutcome = (application: IApplication) => {
    // .. return just the pool outcome if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return renderPoolOutcomeForDownload(application);
    }
    return (
      <PoolOutcomeSelect
        options={POOL_OUTCOME_OPTIONS}
        application={application}
        onPoolOutcomeCreate={onPoolOutcomeCreate}
        field="status"
        dataRole="pool-outcome-selection"
      />
    );
  };

  // A custom renderer for the college change column
  const renderCollegeChange = (application: IApplication) => {
    // .. return just the college change if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return renderCollegeChangeForDownload(application);
    }
    return (
      <PoolOutcomeSelect
        options={descriptions && descriptions.collegePreferences}
        application={application}
        onPoolOutcomeCreate={onPoolOutcomeCreate}
        field="collegePreference"
        dataRole="college-change-selection"
      />
    );
  };

  // A custom renderer for the subject change column
  const renderSubjectChange = (application: IApplication) => {
    // .. return just the subject change if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return renderSubjectChangeForDownload(application);
    }
    return (
      <PoolOutcomeSubjectSelect
        subjectsAndOptions={subjectsAndOptions}
        application={application}
        onPoolOutcomeCreate={onPoolOutcomeCreate}
        dataRole="subject-change-selection"
      />
    );
  };

  // A custom renderer for the entry year change column
  const renderEntryYearChange = (application: IApplication) => {
    const defaultValue = renderEntryYearChangeForDownload(application);

    // .. return just the entry year change if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return defaultValue;
    }
    return (
      <PoolOutcomeAdmitYearInput
        application={application}
        onPoolOutcomeCreate={onPoolOutcomeCreate}
        defaultValue={defaultValue}
      />
    );
  };

  const columns = [
    ...STATIC_COLUMNS,
    {
      key: "provisionalCollegeDecision",
      title: "Provisional College Decision",
      secondaryText: "Most recent provisional decision made on application",
      render: renderStatus,
      renderForDownload: (application: IApplication) =>
        (application.latestDecision &&
          application.latestDecision.typeDescription) ||
        "",
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestDecisionDescription',
    },
    {
      key: "comments",
      title: "Comments",
      secondaryText: "Free-form textual comment",
      render: renderComment,
      renderForDownload: (application: IApplication) =>
        application.comments || "",
      ordering: "comments",
    },
    {
      key: "pool-outcome",
      title: "Pool outcome",
      secondaryText:
        "The status of an application at the completion of the pooling process",
      render: renderPoolOutcome,
      renderForDownload: renderPoolOutcomeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeStatus',
    },
    {
      key: "college-change",
      title: "College Change",
      secondaryText: "The college offered to the candidate.",
      render: renderCollegeChange,
      renderForDownload: renderCollegeChangeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeCollegePreference',
    },
    {
      key: "subject-change",
      title: "Subject Change",
      secondaryText: "The updated subject of the candidate.",
      render: renderSubjectChange,
      renderForDownload: renderSubjectChangeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeSubject',
    },
    {
      key: "entry-year-change",
      title: "Entry Year Change",
      secondaryText: "The applicant's updated entry year.",
      render: renderEntryYearChange,
      renderForDownload: renderEntryYearChangeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeAdmitYear',
    },
  ];

  // The renderer for the assessment score columns
  const renderAssessmentScore =
    (interviewAssessmentType: IAssessmentType) =>
    (application: IApplication) => {
      const assessmentScore = application.assessmentScores.find(
        (assessmentScore) =>
          assessmentScore.typeId === interviewAssessmentType.id,
      );
      const score = assessmentScore && assessmentScore.score;
      if (interviewAssessmentType.id === ASSESSMENT_TYPE_INTERVIEW_AVERAGE) {
        // if it's an interview average, render that
        return renderAverageInterviewScore(application, score);
      } else if (!interviewAssessmentType.isInterview) {
        // else if the type isn't an interview just render the score
        return score;
      }
      // format the interview score with 1dp
      const interviewScore = score && score.substr(0, score.length - 1);
      // if the user doesn't have edit permission then render the interview score
      if (!isScoreEditable(application.permissions, assessmentScore)) {
        return interviewScore;
      }
      return (
        <InterviewAssessmentScoreInput
          interviewAssessmentType={interviewAssessmentType}
          application={application}
          onUpdate={onUpdate}
          defaultValue={interviewScore}
        />
      );
    };

  // defines the assessment columns.
  const assessmentTypes =
    (descriptions &&
      descriptions.assessmentTypes.sort((a, b) =>
        a.description.localeCompare(b.description),
      )) ||
    [];

  columns.push(
    ...assessmentTypes.map((assessmentType, index) => ({
      // hack to differentiate between 'Number of A GCSEs' and 'Number of A* GCSEs'
      key: kebabCase(assessmentType.description.replace("*", "x")),
      title: assessmentType.description,
      secondaryText: assessmentType.longDescription,
      render: renderAssessmentScore(assessmentType),
      ordering: "rankingAssessmentScore",
      orderingQuery: { rankingAssessmentType: assessmentType.id },
      renderForDownload: (application: IApplication) => {
        const assessmentScore = application.assessmentScores.find(
          (assessmentScore) => assessmentScore.typeId === assessmentType.id,
        );
        return (assessmentScore && assessmentScore.score) || "";
      },
    })),
  );
  return columns;
};

/**
 * Component to render a list of `IApplication` objects as a table. The component doesn't decide
 * itself which of the large number of fields to render as columns. Instead it exports
 * `generateAllColumns()` which returns a list of `IColumnDefinition` objects, each defining a
 * column. The consumer can then select which columns to using the `columns` property
 *
 * Issue-619: During migration to MUI v5 and React v17 the table rendering in **development** mode
 * slowed significantly. This is especially the case when the table contains many columns with
 * inputs. Rendering slows as more rows are loaded. The cause is unknown. Performing suggests it is
 * related to styling.
 */
const ApplicationsPageTable: React.FunctionComponent<IProps> = ({
  applications,
  orderingState,
  onOrderChange,
  onViewApplication = () => null,
  columns,
  classes,
}) => {
  // Updates the tableOrdering state and dispatch a requested for reordered data
  const handleTableSort = React.useMemo(
    () => (column: IColumnDefinition) => {
      if (orderingState.key === column.key) {
        if (orderingState.direction === "desc") {
          onOrderChange({
            key: column.key,
            direction: "asc",
          });
        } else if (orderingState.direction === "asc") {
          onOrderChange({ key: "", direction: "" });
        }
      } else {
        onOrderChange({ key: column.key, direction: "desc" });
      }
    },
    [onOrderChange, orderingState],
  );

  const dataProps = { "data-role": "applicationTable" };

  return (
    <>
      <Table
        stickyHeader
        className={`${classes.infoChips} ${classes.table}`}
        size="small"
        {...dataProps}
      >
        <TableHead>
          <TableRow>
            <TableCell
              className="th-actions"
              padding="normal"
              id="full-view-column"
              key={"full-view"}
            >
              Full Details
            </TableCell>
            {columns.map((column) => {
              const dataProps = {
                "data-role": `${camelCase(column.key)}Header`,
              };
              return (
                <TableCell
                  id={`${kebabCase(column.title)}-column`}
                  className={`th-${column.key}`}
                  key={column.key}
                  {...dataProps}
                >
                  {column.ordering ? (
                    <TableSortLabel
                      active={orderingState.key === column.key}
                      direction={orderingState.direction || undefined}
                      onClick={() => handleTableSort(column)}
                    >
                      {column.title}
                    </TableSortLabel>
                  ) : (
                    column.title
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  color="text.hint"
                  p={2}
                >
                  <Typography variant="body1">No data</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            applications.map((application) => (
              <MemoisedApplicationRow
                key={application.camsisApplicationNumber}
                application={application}
                columns={columns}
                onViewApplication={onViewApplication}
              />
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
};

// The properties for the ApplicationRow component
interface IApplicationRowProps extends ICommonProps {
  // The application for the row to display.
  application: IApplication;
}

// A component rendering an `IApplication` as a row in a table.
const ApplicationRow: React.FunctionComponent<IApplicationRowProps> = ({
  application,
  columns,
  onViewApplication = () => null,
}) => {
  const dataProps = {
    "data-role": "application",
    "data-camsis-application-number": application.camsisApplicationNumber,
  };
  return (
    <TableRow {...dataProps}>
      <TableCell padding="normal">
        <Tooltip title="Full Application Details" aria-label="view-source">
          <IconButton
            id="view-source"
            onClick={() => onViewApplication(application)}
            size="large"
          >
            <DescriptionOutlinedIcon
              aria-controls="application-source-dialog"
              aria-haspopup="true"
            />
          </IconButton>
        </Tooltip>
      </TableCell>
      {columns.map((column: any) => {
        const dataProps = { "data-role": column.key };
        return (
          <TableCell
            aria-labelledby={`${kebabCase(column.title)}-column`}
            className={`td-${column.key}`}
            key={camelCase(column.key)}
            {...dataProps}
          >
            {column.render(application)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

// We memoise ApplicationRow to prevent all row re-rendering when we append more application rows
// to the table. Note the do re-render all rows when new columns are selected.
const MemoisedApplicationRow = React.memo(
  ApplicationRow,
  (prevProps, nextProps) => {
    const prevColumns = prevProps.columns.map((column) => column.key).join("");
    const nextColumns = nextProps.columns.map((column) => column.key).join("");
    // compare the applications and the column selections
    return (
      prevColumns === nextColumns &&
      prevProps.application === nextProps.application
    );
  },
);

export default withStyles(styles)(ApplicationsPageTable);
