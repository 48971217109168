import * as React from "react";
import { IApplication, IDescription, IPoolOutcomeCreate } from "../api";
import { ApplicationTableMenuItem } from "./ApplicationTableMenuItem";
import { ApplicationTableSelect } from "./ApplicationTableSelect";

/**
 * Properties for PoolOutcomeSelect
 */
interface IPoolOutcomeSelectProps {
  /** The list of `IDescription` objects to use in select options */
  options: IDescription[] | null;
  /** The target application */
  application: IApplication;
  /** Which `IPoolOutcomeCreate` field this component should target */
  field: "status" | "collegePreference" | "subject";
  /** The handler for when a pool outcome field value is selected */
  onPoolOutcomeCreate: (poolOutcome: IPoolOutcomeCreate) => void;
  /** To allow better external visbility, if set, will add a 'data-role' property to the input */
  dataRole?: string;
}

/**
 * Component to render a input select for a pool outcome field. When a selection is made the
 * component dispatches a pool outcome creation event updating the targeted field.
 */
const PoolOutcomeSelect: React.FunctionComponent<IPoolOutcomeSelectProps> = ({
  options,
  application,
  onPoolOutcomeCreate,
  field,
  dataRole,
}) => {
  const inputProps: any = {};
  if (dataRole) {
    inputProps["data-role"] = dataRole;
  }

  // handler for select changes events - dispatches an `IPoolOutcomeCreate` populated by
  // `latestPoolOutcome` and the selected value.
  const onChange = (event: any) => {
    const poolOutcomeCreate: any = {
      status: null,
      ...(application.latestPoolOutcome || {}),
      applicationId: application.camsisApplicationNumber,
    };
    poolOutcomeCreate[field] = event.target.value;
    onPoolOutcomeCreate(poolOutcomeCreate);
  };

  const value =
    application.latestPoolOutcome && application.latestPoolOutcome[field];

  return (
    <ApplicationTableSelect
      variant="standard"
      inputProps={inputProps}
      value={value || ""}
      onChange={onChange}
    >
      {(options || []).map((options) => (
        <ApplicationTableMenuItem key={options.id} value={options.id}>
          {options.description}
        </ApplicationTableMenuItem>
      ))}
    </ApplicationTableSelect>
  );
};

export default PoolOutcomeSelect;
