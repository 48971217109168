/**
 * Support for interacting with the webapp's "API like" endpoints.
 */
// Get Django's CSRF token from the page from the first element named "csrfmiddlewaretoken". If no
// such element is present, the token is empty.
const CSRF_ELEMENT = document.getElementsByName(
  "csrfmiddlewaretoken",
)[0] as HTMLInputElement;
export const CSRF_TOKEN =
  typeof CSRF_ELEMENT !== "undefined" ? CSRF_ELEMENT.value : "";

// Headers to send with fetch request.
const API_HEADERS = {
  "Content-Type": "application/json",
  "X-CSRFToken": CSRF_TOKEN,
};

export const API_BASE =
  window.location.protocol + "//" + window.location.host + "/api";
export const CAO_DASHBOARD_BASE =
  window.location.protocol + "//" + window.location.host + "/caoDashboard";

/**
 * When API calls fail, the related Promise is reject()-ed with an object implementing this
 * interface.
 */
export interface IError {
  /** A descriptive error. */
  error: Error;
  /** The response from the API, if any. */
  response?: Response;
  /** The decoded JSON response, if available */
  body?: any;
}

export class ApiError implements IError {
  response?: Response;
  body?: any;

  constructor(
    public error: Error,
    response?: Response,
    body?: any,
  ) {
    this.error = error;
    this.response = response;
    this.body = body;
  }
}

export const isApiError = (error: any): error is IError => {
  return error instanceof ApiError;
};

/**
 * A wrapper around fetch() which performs an API request. Returns a Promise which is resolved with
 * the decoded JSON body of the response (unless method is DELETE) or which is rejected in case of
 * an error.
 *
 * Any errors are *always* logged via console.error().
 */
export const apiFetch = (
  input: string | Request,
  init: RequestInit = {},
): Promise<any> => {
  // Guard rails against XSRF
  let url: string;
  if (typeof input === "string") {
    url = input;
  } else {
    url = input.url;
  }
  if (!url.startsWith(API_BASE) || url.includes("..")) {
    throw new Error(`URL does not match whitelist: ${input}`);
  }

  // Use the original input, not URL
  //
  // NOTE: we throw if there is an XSRF problem, so input should not contain
  // an evil URL.
  //
  // apiFetch supports us sending a Request object,
  // so we need to flow that into fetch; it is currently implied via
  // 'A wrapper around fetch()' in the docstring that if you
  // provide a Request, the whole Request is used, not just the URL
  return fetch(input, {
    credentials: "include",
    ...init,
    headers: {
      ...API_HEADERS,
      ...init.headers,
    },
  })
    .then((response) => {
      if (!response || !response.ok) {
        const error = new ApiError(
          new Error(`API request returned error: ${response.statusText}`),
          response,
        );

        // Reject the call passing the response parsed as JSON.
        // TODO we are currently unable to read the bodies of error responses.
        // see https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/8
        return response
          .json()
          .catch(() => Promise.reject(error))
          .then((body) => {
            error.body = body;
            return Promise.reject(error);
          });
      }

      // Parse response body as JSON (unless it was a delete).

      if (init.method === "DELETE") {
        return null;
      }
      return response.json();
    })
    .catch((error) => {
      // Always log any API errors we get.
      // tslint:disable-next-line:no-console
      console.error("API fetch error:", error);

      // Chain to the next error handler
      return Promise.reject(error);
    });
};

/** A generic list of resources returned from a resource list endpoint. */
export interface IResourceListResponse<Resource> {
  results: Resource[];
  next?: string;
  previous?: string;
  page: number;
  count: number;
}

/** A descriptions resource */
export interface IDescription {
  id: string;
  description: string;
}

/**
 * Interface describing a role that a user can invite to
 */
export interface IInvitableRole extends IDescription {
  collegeRequired: boolean;
  subjectRequired: boolean;
}

/**
 * Interface for a group that a user can be a member of.
 */
export interface IGroup {
  id: number;
  name: string;
}

/**
 * Interface for a profile object returned from the API.
 */
export interface IProfile {
  isAnonymous: boolean;
  isSuperuser: boolean;
  username: string;
  displayName: string;
  email: string;
  avatarUrl: string | null;
  dateJoined: Date;
  groups: IGroup[];
  invitableRoles: IInvitableRole[];
}

/** Fetch the user's profile. */
export const profileGet = (): Promise<IProfile> => {
  return apiFetch(API_BASE + "/profile/me/");
};

/** A full description type resource */
export interface IFullDescription extends IDescription {
  longDescription: string;
}

/** An assessment type resource */
export interface IAssessmentType extends IFullDescription {
  isInterview: boolean;
}

export interface IDescriptionsResponse {
  decisionTypes: IFullDescription[];
  assessmentTypes: IAssessmentType[];
  annotationTypes: IDescription[];
  subjects: IDescription[];
  collegePreferences: IDescription[];
}

export const descriptionsGet = (): Promise<IDescriptionsResponse> => {
  return apiFetch(appendQuery(API_BASE + "/descriptions/", {}));
};

/** A base interface for pool outcome resources */
interface IPoolOutcomeBase {
  status: "ACCEPT" | "REJECT" | "HOLD" | "OFFER" | "INTERVIEW" | null;
}

/** A pool outcome create resource */
export interface IPoolOutcomeCreate extends IPoolOutcomeBase {
  applicationId: string;
  subjectOptions?: string[];
  admitTerm?: string;
  admitYear?: number;
  collegePreference?: string;
  subject?: string;
  interviewCollege1?: string;
  interviewCollege2?: string;
}

/** A pool outcome resource */
export interface IPoolOutcome extends IPoolOutcomeBase {
  id: string;
  url: string;
  recordedAt: string;
  recordedBy: IPerson;
  collegePreference: string;
  collegePreferenceDescription: string;
  subject: string;
  subjectDescription: string;
  subjectAbbreviation: string;
  subjectOptions: string[];
  admitTerm: string;
  admitYear: number;
  interviewCollege1: string | null;
  interviewCollege2: string | null;
  interviewCollege1Description?: string;
  interviewCollege2Description?: string;
}

/** Create a new invitation. */
export const poolOutcomeCreate = (
  body: IPoolOutcomeCreate,
): Promise<IPoolOutcome> => {
  return apiFetch(`${API_BASE}/poolOutcomes/`, {
    body: JSON.stringify(body),
    method: "POST",
  });
};

/** A mixin for any endpoint that supports single option global filters. */
export interface ISingleSelectFilters {
  // filter by subject option
  subjectOption?: string;
  // Filter by subject group. Note that this isn't currently supported by the api - however it is
  // likely that it will be supported in the future and it's also convenient to use this to
  // preserve the current filter context.
  subjectGroupId?: string;
}

/** A mixin for any endpoint that supports multi option global filters. */
export interface IMultiSelectFilters {
  // filter by subject id
  subjectId?: string | string[];
  // filter by latest decision
  latestDecisionTypeId?: string | string[];
  // filter by college preference
  collegePreferenceId?: string | string[];
  // filter by flag
  flagTypeId?: string | string[];
  // filter by pool type
  poolType?: string | string[];
  // filter by pool status
  poolStatus?: string | string[];
  // filter by college decision
  collegeDecision?: string | string[];
}

/** A mixin for any endpoint that support the global filters. */
export interface IGlobalFilters
  extends ISingleSelectFilters,
    IMultiSelectFilters {}

/** An assessment score's permissions */
export interface IAssessmentScorePermissions {
  update: boolean;
}

/** An assessment score resource */
export interface IAssessmentScore {
  score: string | null;
  typeId: string;
  typeDescription: string;
  permissions: IAssessmentScorePermissions;
}

/** A person score resource */
export interface IPerson {
  displayName: string;
  forenames: string;
  lastName: string;
  prefFirstName: string;
  ucasPersonalId: string;
  camsisUsn: string;
}

/** A decision resource */
export interface IDecision {
  recordedAt: string;
  recordedBy: IPerson;
  typeId: string;
  typeDescription: string;
  typeLongDescription: string;
}

/** An annotation that can be associated with an application */
export interface IAnnotation {
  type: IDescription;
  value: string;
}

/** An application's permissions */
export interface IApplicationPermissions {
  update: boolean;
  createDecisions: boolean;
  createPoolOutcomes: boolean;
  createAssessmentScores: boolean;
  createInterviewAssessmentScores: boolean;
  updateComments: boolean;
}

/** Individual piece of data from an application's source row */
export interface IApplicationSourceData {
  heading: string;
  value: string;
}

/** An application detail's source */
export interface IApplicationSource {
  id: string;
  lastImportedAt: string;
  row: IApplicationSourceData[];
}

/** An external resource for an application */
export interface IExternalResourceSummary {
  id: string;
  url: string;
  externalUrl: string;
  description: string;
}

/** An application resource */
export interface IApplication {
  url: string;
  camsisApplicationNumber: string;
  camsisStatusDescription: string;
  candidate: IPerson;
  subjectDescription: string;
  collegePreference: string;
  collegePreferenceDescription: string;
  latestDecision: IDecision | null;
  latestDecisionTypeId: string;
  latestPoolOutcome: IPoolOutcome | null;
  assessmentScores: IAssessmentScore[];
  annotations: IAnnotation[];
  permissions: IApplicationPermissions;
  predictedGrades: string | null;
  comments: string | null;
  subject: string;
  subjectOptions: string[];
  admitTerm: string;
  admitYear: number;
  year: number;
  sources?: IApplicationSource[];
  externalResources: IExternalResourceSummary[];
  poolType?: string;
  poolStatus?: string;
  poolTypeDescription: string;
  poolStatusDescription: string;
  gcseSchoolPostcode: string;
  gcseSchoolName: string;
  schoolName: string;
  homePostcode: string;
  birthdate: string | null;
  countryOfDomicile: string;
  collegeDecision: string;
  collegeDecisionDescription: string;
}

/** A query where a paged result is expected. */
export interface IPageQuery {
  // which page of applications to return (defaults to 1)
  page?: number;
  // the number of applications to return in a page (defaults to 100)
  page_size?: number;
}

/** A query to the application list endpoint. */
export interface IApplicationQuery extends IGlobalFilters, IPageQuery {
  // search for applications matching the search term
  search?: string;
  // which application field to sort by
  ordering?: string;
  // the id of the assessment type to sort by when ordering=rankingAssessmentScore
  rankingAssessmentType?: string;
}

/** An application list response. */
export type IApplicationListResponse = IResourceListResponse<IApplication>;

/** Retrieve a list(page) of application resources. */
export const applicationList = (
  query: IApplicationQuery = {},
  endpoint?: string | null,
): Promise<IApplicationListResponse> => {
  return apiFetch(appendQuery(endpoint || API_BASE + "/applications/", query));
};

/** An application patch resource */
export interface IApplicationPatch {
  camsisApplicationNumber: string;
  latestDecisionTypeId?: string;
  assessmentScores?: IAssessmentScore[];
  comments?: string | null;
}

/** Patch an existing application resource. */
export const applicationPatch = (
  url: string,
  item: IApplicationPatch,
): Promise<IApplication> => {
  return apiFetch(url, {
    body: JSON.stringify(item),
    method: "PATCH",
  });
};

export type SecondAxis =
  | ""
  | "subject"
  | "collegePreference"
  | "latestDecision"
  | "admitYear";

/** A query to the application counts endpoint. */
export interface IApplicationCountsQuery extends IGlobalFilters {
  admitYear?: number;
  includeAnnotations?: boolean;
  secondAxis?: SecondAxis;
}

/** An annotation value for nn annotation count. */
export interface IAnnotationValue {
  value: string;
  count: number;
}

/** An annotation count for the application. */
export interface IAnnotationCount {
  typeId: string;
  typeDescription: string;
  count: number;
  values: IAnnotationValue[];
}

/** An application counts response. */
export interface IApplicationCountsResponse {
  count: number;
  secondAxis: SecondAxis;
  secondAxisDescription: string;
  secondAxisCounts: {
    secondAxisId: string;
    secondAxisDescription: string;
    count: number;
    annotations: IAnnotationCount[];
  }[];
}

/** Retrieve the application counts resource. */
export const applicationCountsGet = (
  query: IApplicationCountsQuery = {},
  endpoint?: string | null,
): Promise<IApplicationCountsResponse> => {
  return apiFetch(
    appendQuery(endpoint || API_BASE + "/applicationCounts/", query),
  );
};

/** Retrieve the detailed application resource. */
export const applicationDetailGet = (
  application: IApplication,
): Promise<IApplication> => {
  return apiFetch(application.url);
};

/** A query to the assessmentScoreHistogram endpoint. */
export interface IAssessmentScoreHistogramQuery extends IGlobalFilters {
  rankingAssessmentType?: string;
  lowBounds?: string;
  secondAxis?: SecondAxis;
}

/** A response from the assessmentScoreHistogram endpoint. */
export interface IAssessmentScoreHistogramResponse {
  count: number;
  rankingAssessmentType: null | {
    id: string;
    description: string;
    longDescription: string;
    isInterview: boolean;
  };
  valueStatistics: {
    minimum: number | null;
    maximum: number | null;
  };
  valueBucketLowBounds: number[];
  valueBucketCounts: {
    count: number;
    interval: { low: null | number; high: null | number };
    secondAxisCounts: {
      id: null | string;
      count: number;
    }[];
  }[];
  secondAxisDescription: string;
  secondAxisValues: {
    id: string;
    description: string;
  }[];
}

/** Retrieve a response from the assessmentScoreHistogram endpoint. */
export const assessmentScoreHistogramGet = (
  query: IAssessmentScoreHistogramQuery,
): Promise<IAssessmentScoreHistogramResponse> =>
  apiFetch(appendQuery(API_BASE + "/assessmentScoreHistogram/", query));

/** The base API for invitations */
const INVITATIONS_API = `${API_BASE}/invitations/`;

export interface IRoleWithContext {
  role: { id: string };
  college?: { id: string };
  subject?: { id: string };
}

/** An invitation create resource */
export interface IInvitationCreate {
  recipient: string;
  roleWithContext: IRoleWithContext;
  message?: string;
}

/** Create a new invitation. */
export const invitationCreate = (
  body: IInvitationCreate,
): Promise<IInvitationCreate> => {
  return apiFetch(INVITATIONS_API, {
    body: JSON.stringify(body),
    method: "POST",
  });
};

/** An invitation resource */
export interface IInvitation {
  url: string;
  issuedAt: string;
  expiresAt: string;
  usedAt: string | null;
  sender: IPerson;
  recipient: string;
  message: string;
  roleWithContext: {
    role: IDescription;
    college?: IDescription;
    subject?: IDescription;
    description: string;
  };
  revokedAt: string | null;
  revokedBy: IPerson | null;
  revokeReason: string | null;
}

/** An invitation list query. */
export type IInvitationQuery = IPageQuery;

/** An invitation list response. */
export type IInvitationListResponse = IResourceListResponse<IInvitation>;

/** Retrieve a list(page) of invitation resources. */
export const invitationList = (
  query: IInvitationQuery = {},
  endpoint?: string,
): Promise<IInvitationListResponse> => {
  return apiFetch(appendQuery(endpoint || INVITATIONS_API, query));
};

/** Posts an invitation accept action. If, successful, the IInvitation is returned. */
export const invitationAccept = (
  id: string,
  token: string,
): Promise<IInvitation> => {
  return apiFetch(`${INVITATIONS_API}${id}/accept/`, {
    body: JSON.stringify({ token }),
    method: "POST",
  });
};

/**
 * Response from the bannerMessages endpoint
 */
export interface IBannerMessage {
  message: string;
  showFrom: string;
  showUntil: string;
  backgroundColour: string;
  foregroundColour: string;
}

export interface IBannerMessageListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IBannerMessage[];
}

/** Retrieve a response from the bannerMessage list endpoint. */
export const bannerMessageList = (): Promise<IBannerMessageListResponse> =>
  apiFetch(API_BASE + "/bannerMessages/");

/**
 * Append to a URL's query string based on properties from the passed object.
 */
const appendQuery = (
  endpoint: string,
  o: { [index: string]: any } = {},
): string => {
  const url = new URL(endpoint);
  Object.keys(o).forEach((key) => {
    if (o[key] !== undefined) {
      if (Array.isArray(o[key])) {
        o[key].forEach((item: any) => url.searchParams.append(key, item));
      } else {
        url.searchParams.append(key, o[key]);
      }
    }
  });
  return url.href;
};

/**
 * Representation of an unexpected unsuccessful response from an API request made during an route
 * loader.
 */
class LoaderResponseError {
  status: number;
  statusText: string;
  error: Error;

  constructor(error: Error, response: Response) {
    this.status = response.status;
    this.statusText = response.statusText;
    this.error = error;
  }
}

export const isLoaderResponseError = (
  error: any,
): error is LoaderResponseError => {
  return error instanceof LoaderResponseError;
};

/**
 * Wraps the apiFetch reraising API errors as a LoaderResponseError for error
 * handling in RootErrorElement.
 */
export const loaderApiFetch = (url: string) => {
  return apiFetch(url).catch((error) => {
    // response is defined for API errors thrown in apiFetch
    if (isApiError(error) && error.response) {
      throw new LoaderResponseError(error.error, error.response);
    }

    throw error;
  });
};

export interface PoolsideMeeting {
  id: string;
  name: string;
  subject: string;
}

export const getPoolsideMeeting = (id: string): Promise<PoolsideMeeting> => {
  return loaderApiFetch(`${API_BASE}/poolsideMeetings/${id}/`);
};
