import * as React from "react";

import Grid from "@mui/material/Grid";
import MUILink from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { type Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "column",
      justifyContent: "left",
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),

      "& a": {
        color: "inherit",
        fontWeight: "inherit",
        textDecoration: "underline",
      },

      "& a:hover": {
        color: theme.palette.text.primary,
      },
    },
    gridContainer: {
      justifyContent: "space-between",
    },
  }),
);

/**
 * A component that is used at the bottom of the navigation panel to display who it was made by.
 * It can contain additional `NavigationPanelFooterLink` components.
 * It is designed to be used as a child component of the `NavigationPanel` component.
 */
export const NavigationPanelFooter: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="caption">
        {"Made by "}
        <MUILink href="https://guidebook.devops.uis.cam.ac.uk/en/latest/">
          UIS DevOps
        </MUILink>
      </Typography>
      <Typography variant="caption">
        &copy; {new Date().getFullYear()} University of Cambridge
      </Typography>
      <Typography variant="caption">
        <Grid container className={classes.gridContainer}>
          {children}
        </Grid>
      </Typography>
    </div>
  );
};

export default NavigationPanelFooter;
