import * as React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { type Theme } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";

import AppBar from "../components/AppBar";
import NavigationPanel from "../components/NavigationPanel";

import BannerMessages from "../components/BannerMessages";
import LoadingIndicator from "../components/LoadingIndicator";
import { ProfileContext } from "../providers/ProfileProvider";

import { useBannerMessageList } from "../apiHooks";
import signInUrl from "../signInUrl";
import { drawerWidth } from "../theme";

const styles = (theme: Theme) =>
  createStyles({
    // Add padding for elements below the drawer on sufficiently large screens.
    drawerPadded: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: drawerWidth,
      },
    },

    drawerPaper: { width: drawerWidth },

    children: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: theme.spacing(2),

      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
    },

    toolbar: theme.mixins.toolbar,
  });

export interface IPageProps extends WithStyles<typeof styles> {
  /** Children of the top-level AppBar content. */
  appBarChildren?: React.ReactNode;
  /** Page can be viewed by anonymous user */
  allowAnonymous?: boolean;
}

/**
 * A top level component that wraps all pages to give then elements common to all page,
 * the ``AppBar`` etc.
 */
const Page: React.FunctionComponent<React.PropsWithChildren<IPageProps>> = ({
  children,
  appBarChildren,
  allowAnonymous = false,
  classes,
}) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

  const profile = React.useContext(ProfileContext);

  const {
    response: { results: bannerMessages = [] } = {},
  } = useBannerMessageList();

  // anonymous users not permitted so redirect them the sign in url
  if (!allowAnonymous && profile && profile.isAnonymous) {
    window.location.href = signInUrl();
    return null;
  }

  const handleDrawerToggle = () => setMobileDrawerOpen((prev) => !prev);

  return (
    <>
      <AppBar
        position="fixed"
        classes={{ root: classes.drawerPadded }}
        onMenuClick={handleDrawerToggle}
        children={appBarChildren}
      />
      <Drawer
        variant="temporary"
        open={mobileDrawerOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{ display: { lg: "none", xs: "block" } }}
      >
        <NavigationPanel />
      </Drawer>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        sx={{ display: { lg: "block", xs: "none" } }}
      >
        <NavigationPanel />
      </Drawer>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        bgcolor="action.hover"
        sx={{ paddingLeft: { xs: 0, lg: `${drawerWidth}px` } }}
      >
        {/* used as a responsive spacer */}
        <div className={classes.toolbar} />

        <BannerMessages messages={bannerMessages} />

        <div className={classes.children}>
          {!profile ? <LoadingIndicator /> : children}
        </div>
      </Box>
    </>
  );
};

export default withStyles(styles)(Page);
