import { type Match } from "containers/ClientRoutes";
import { isEqual } from "lodash";
import ErrorProvider from "providers/ErrorProvider";
import React from "react";
import { Outlet, useLocation, useMatches, useSearchParams } from "react-router";
import { sendAnalytics, splitObjectWithKeys } from "utils";

// A key used to store search parameters in sessionStorage so that it persists while navigating the
// application.
export const STORED_SEARCH_PARAMS_KEY = "storedSearchParams";

// We store search parameters which do not apply to the page in the session storage so that it
// persists within the current browsing session and will be restored if the user navigates away
// from a page and returns.
// Deliberately using sessionStorage API over useSessionStorage hook to avoid unnecessary
// re-renders.
const getStoredSearchParams = (): Record<string, any> => {
  return JSON.parse(sessionStorage.getItem(STORED_SEARCH_PARAMS_KEY) || "{}");
};

const setStoredSearchParams = (params: Record<string, any>) => {
  sessionStorage.setItem(STORED_SEARCH_PARAMS_KEY, JSON.stringify(params));
};

export const RouteMiddleware = () => {
  const location = useLocation();

  React.useEffect(() => {
    const pathname = location.pathname;
    sendAnalytics("set", "page_path", pathname);
    sendAnalytics(
      "set",
      "page_title",
      pathname.charAt(1).toUpperCase() + pathname.slice(2),
    );
    sendAnalytics("event", "page_view");
  }, [location]);

  const matches = useMatches() as Match[];
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Set of search parameters used by the route. These are additive so parent route search
   * parameters are unioned.
   */
  const searchParamsKeys = React.useMemo(
    () =>
      matches.reduce((params, routeMatch) => {
        if (!routeMatch.handle?.searchParams) return params;

        return new Set([...params, ...routeMatch.handle.searchParams]);
      }, new Set<string>()),
    [matches],
  );

  const searchParamsAsObj = React.useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams],
  );

  /**
   * Moves search parameters between search parameters in the URL and session storage.
   */
  React.useEffect(() => {
    const storedSearchParams = getStoredSearchParams();

    // Search parameters from the URL divided into parameter relevant to the page and those which
    // are not.
    const [pageSearchParams, restOfSearchParams] = splitObjectWithKeys(
      searchParamsAsObj,
      searchParamsKeys,
    );
    // Search parameters from the local storage divided into parameter relevant to the page and
    // those which are not.
    const [storedPageSearchState, restOfSearchState] = splitObjectWithKeys(
      storedSearchParams,
      searchParamsKeys,
    );

    const desiredSearchParams = {
      ...pageSearchParams,
      ...storedPageSearchState,
    };
    const desiredStoreState = { ...restOfSearchState, ...restOfSearchParams };

    if (!isEqual(searchParamsAsObj, desiredSearchParams)) {
      setSearchParams(desiredSearchParams);
    }

    if (!isEqual(storedSearchParams, desiredStoreState)) {
      setStoredSearchParams(desiredStoreState);
    }
  }, [searchParamsKeys, searchParamsAsObj, setSearchParams]);

  return (
    <ErrorProvider>
      <Outlet />
    </ErrorProvider>
  );
};

export default RouteMiddleware;
