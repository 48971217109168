import React from "react";

import { Navigate } from "react-router";
import { ProfileContext } from "../providers/ProfileProvider";
import { hasAnyGroup } from "../utils/groups";

interface Props extends React.PropsWithChildren {
  /** The user is allowed to access the route if they are a member of one or
   * more of the specified groups. */
  anyOneOfGroups: Set<string>;
  redirectPath?: string;
}

const PermissionedRoute = ({
  anyOneOfGroups,
  redirectPath = "/summary",
  children,
}: React.PropsWithChildren<Props>) => {
  const profile = React.useContext(ProfileContext);
  const isAllowed = hasAnyGroup(profile, anyOneOfGroups);

  // only redirect if the profile has been retrieved and the user is not allowed
  if (profile && !isAllowed) return <Navigate to={redirectPath} replace />;

  return <>{children}</>;
};

export default PermissionedRoute;
