import { Box, Link, Paper, Typography } from "@mui/material";
import Page from "../containers/Page";

const NoMatchPage = () => (
  <Page allowAnonymous>
    <Paper>
      <Box p={8}>
        <Typography variant="h6" paragraph>
          Page not found
        </Typography>
        <Typography variant="body1" paragraph>
          This could be because the web address was not correct, or you
          don&apos;t have permission to view the page.
        </Typography>
        <Typography variant="body1" paragraph>
          If you typed the web address, check it is correct.
        </Typography>
        <Typography variant="body1" paragraph>
          If you pasted the web address, check you copied the entire address.
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions, contact{" "}
          <Link href="mailto:pools@admin.cam.ac.uk" underline="always">
            pools@admin.cam.ac.uk
          </Link>
          .
        </Typography>
      </Box>
    </Paper>
  </Page>
);

export default NoMatchPage;
