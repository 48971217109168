import * as React from "react";

import { useSortable } from "@dnd-kit/sortable";
import DragIcon from "@mui/icons-material/DragIndicator";
import { ListItem, ListItemIcon, type Theme } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";

export interface SortableListItemProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  secondaryAction: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles({
    sortableColumn: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    draggingItem: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      cursor: "grab",
      zIndex: 1,

      "& .MuiListItemIcon-root": {
        color: theme.palette.text.primary,
      },
    },
    dragIcon: {
      cursor: "grab",
      "&:hover": {
        color: theme.palette.text.primary,
      },
    },
  });

export const SortableListItem = withStyles(styles)(
  ({ id, children, secondaryAction, classes }: SortableListItemProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id,
    });

    const style = {
      // y only transform to lock x position
      transform: `translate3d(0px, ${transform?.y ?? 0}px, 0)`,
      transition,
    };

    return (
      <ListItem
        className={`${classes.sortableColumn} ${isDragging ? classes.draggingItem : ""}`}
        style={style}
        ref={setNodeRef}
        {...attributes}
      >
        <ListItemIcon className={classes.dragIcon} {...listeners}>
          <DragIcon />
        </ListItemIcon>
        {children}
        {secondaryAction}
      </ListItem>
    );
  },
);
