import { debounce } from "lodash";
import * as React from "react";
import { useMemo } from "react";

import { IApplication, IApplicationPatch } from "../api";
import { ApplicationTableTextField } from "./ApplicationTableTextField";

/**
 * Properties for CommentsInput
 */
interface ICommentsInputProps {
  // The application being updated
  application: IApplication;
  // The handler for when a row is updated.
  onUpdate: (patch: IApplicationPatch) => void;
}

/**
 * Component to render a comments input. The component also prepares and dispatches a patch update.
 */
const CommentsInput: React.FunctionComponent<ICommentsInputProps> = ({
  application,
  onUpdate,
}) => {
  // handles updates to the input on a delay
  // Note: useMemo stops the debounce from being recreated every render
  const delayedOnChange = useMemo(
    () =>
      debounce((value) => {
        onUpdate({
          camsisApplicationNumber: application.camsisApplicationNumber,
          comments: value === "" ? null : value,
        });
      }, 500),
    [application, onUpdate],
  );

  return (
    <ApplicationTableTextField
      onChange={(event: any) => delayedOnChange(event.target.value)}
      defaultValue={application.comments || ""}
      variant="standard"
    />
  );
};

export default CommentsInput;
