import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import React from "react";
import { type SortableListItemProps } from "./SortableListItem";

type SortableListProps = {
  /**
   * Array of list identifiers. This the order of the list items rendered from
   * top to bottom. This prop should be updated when the `onChange` function is
   * called.
   */
  orderedListIds: string[];
  /**
   * Function called when the order of the list has changed. This should updates
   * the `orderedListIds` prop.
   * @param newOrder new order of the list identifiers.
   */
  onChange: (newOrder: string[]) => void;
  /**
   * Sortable list items which are rendered by this component. This must be the
   * same order as orderedListIds.
   */
  children: React.ReactElement<SortableListItemProps>[];
};

export const SortableList = ({
  children,
  orderedListIds,
  onChange,
}: SortableListProps) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = orderedListIds.indexOf(active.id as string);
      const newIndex = orderedListIds.indexOf(over.id as string);
      onChange(arrayMove(orderedListIds, oldIndex, newIndex));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={orderedListIds}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};
