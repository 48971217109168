import CloseIcon from "@mui/icons-material/Close";
import { type Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MuiSnackbar from "@mui/material/Snackbar";
import { type WithStyles, withStyles } from "@mui/styles";
import React from "react";

// A global callback which can be used to set the snackbar message.
//
// This is a little bit of a HACK based on the approach in [1]. Really this should be flux-y rather
// than callback-y but it's not worth the overhead of selecting and adding some flux implementation
// just for the snackbar at the moment. If we have some other global state in the frontend, we
// should migrate this to whatever flux implementation we end up using.
//
// [1] https://medium.freecodecamp.org/how-to-show-informational-messages-using-material-ui-in-a-react-web-app-5b108178608
let globalShowMessageFunc: any = null;

// The snackbar implementation itself is based on the "Consecutive Snackbars" example from the
// Material UI docs [2].
//
// [2] https://material-ui.com/demos/snackbars/

const styles = (theme: Theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
});

interface IMessageInfo {
  // a key required by MuiSnackbar for handling consecutive snackbars
  key?: number;
  // the message to be displayed
  message?: string;
}

interface ISnackbarState {
  // the message info for display
  messageInfo: IMessageInfo;
  // whether or not the snackbar is displayed
  open: boolean;
}

class Snackbar extends React.Component<
  WithStyles<typeof styles>,
  ISnackbarState
> {
  public queue: IMessageInfo[] = [];

  public state: ISnackbarState = {
    messageInfo: {},
    open: false,
  };

  componentDidMount() {
    // When the component mounts, update the global showMessageHandler variable. In essence this
    // means that the last mounted snackbar components "wins" the right to show future messages.
    globalShowMessageFunc = this.showMessage;
  }

  componentWillUnmount() {
    // If the current showMessageHandler is our one, unset it to prevent callers to the global
    // showMessage() function calling a handler after we've unmounted.
    if (globalShowMessageFunc === this.showMessage) {
      globalShowMessageFunc = null;
    }
  }

  showMessage = (message: string): void => {
    this.queue.push({
      key: new Date().getTime(),
      message,
    });

    if (this.state.open) {
      // immediately begin dismissing current message to start showing new one
      this.setState({ open: false });
    } else {
      this.processQueue();
    }
  };

  processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        messageInfo: { ...this.queue.shift() },
        open: true,
      });
    }
  };

  handleClose = (event?: object, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  handleExited = () => {
    this.processQueue();
  };

  render() {
    const { classes } = this.props;
    const { message, key } = this.state.messageInfo;
    return (
      <MuiSnackbar
        key={key}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        TransitionProps={{ onExited: this.handleExited }}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={() => this.handleClose()}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

export default withStyles(styles)(Snackbar);

/**
 * Show a message to the user.
 */
export const showMessage = (message: string): void => {
  // We would normally show an error to the user if there is no handler but since this is our "show
  // message to the user" code path, we can't really do anything other than drop the message :S.
  if (!globalShowMessageFunc) {
    // tslint:disable-next-line:no-console
    console.error("globalShowMessageFunc is undefined");
    return;
  }

  globalShowMessageFunc(message);
};
