import * as React from "react";
import Page from "../containers/Page";

const PoolsideMeetingsPage = () => {
  return (
    <Page>
      <h3>Poolside Meetings</h3>
    </Page>
  );
};

export default PoolsideMeetingsPage;
