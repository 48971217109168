/**
 * Hooks derived from the `useSearchParams` hook from React Router.
 */
import { SortColumn as SummaryOrderingKey } from "components/SummaryTable";
import React from "react";
import { useSearchParams } from "react-router";

type OrderingDirection = "" | "desc" | "asc";

export interface OrderingState<KT extends string = string> {
  key: KT;
  direction: OrderingDirection;
}

// Filtering keys
const POOL_TYPES_KEY = "poolTypes";
const POOLED_STATUSES_KEY = "pooledStatuses";
const FLAGS_KEY = "keys";
const COLLEGES_KEY = "colleges";
const SUBJECTS_KEY = "subjects";
const DECISIONS_KEY = "decisions";

// Ordering keys
const ORDERING_KEY = "orderingKey";
const DIRECTION_KEY = "orderingDirection";

type QueryParamHookInit = {
  allowedValues: string[] | undefined;
};

/**
 * Function to create a hooks which store state as a comma separated list of
 * values to a query parameter.
 *
 * For example:
 *  ?colleges=CAI,CC&poolTypes=SUMMER,WINTER
 *
 * @param key query parameter key
 * @returns hook which retrieves and sets a list of values to a query parameter
 */
const createQueryParamListHook = (key: string) => {
  return (
    { allowedValues }: QueryParamHookInit = { allowedValues: undefined },
  ): [string[], (value: string[]) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const state = React.useMemo(() => {
      let values = searchParams
        .get(key)
        ?.split(",")
        .map((p) => decodeURIComponent(p));

      // sanitize values based on allowedValues
      if (allowedValues) {
        values = values?.filter((v) => allowedValues.includes(v));
      }

      return values || [];
    }, [searchParams, allowedValues]);
    const setState = (values: string[]) => {
      // if values are empty, delete the key from the search params
      if (values.length === 0) {
        searchParams.delete(key);
        setSearchParams(searchParams);
        return;
      }

      searchParams.set(key, values.map((v) => encodeURIComponent(v)).join(","));
      setSearchParams(searchParams);
    };

    return [state, setState];
  };
};

/**
 * Function to create a hooks which store a single value of state as a query parameter.
 *
 * For example:
 *  ?subjects=NSTX
 *
 * @param key query parameter key
 * @returns hook which retrieves and sets a value to a query parameter by the provided key
 */
const createQueryParamHook = (key: string) => {
  const useListHook = createQueryParamListHook(key);

  return (
    init: QueryParamHookInit,
  ): [string | undefined, (value: string | undefined) => void] => {
    const [listState, setListState] = useListHook(init);

    const state = listState.length > 0 ? listState[0] : undefined;
    const setState = (value: string | undefined) => {
      if (value === undefined) {
        setListState([]);
      } else {
        setListState([value]);
      }
    };
    return [state, setState];
  };
};

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Function to create hooks which store ordering state in URL as search parameters. Use
 * {@argument keyPrefix} to customize the prefix of the keys in the URL.
 *
 * @param initState initial ordering state. Note, this state is not visible in the URL.
 * @return Hook to get and set the ordering state stored in the URL with keys starting with prefix,
 * and a set of ordering keys.
 * @see OrderingState
 */
const createOrderingHook = <KT extends string = string>(
  keyPrefix: string = "",
): [
  (
    initState?: OrderingState<KT>,
  ) => [Partial<OrderingState<KT>>, (value: OrderingState<KT>) => void],
  Set<string>,
] => {
  let orderingKey: string;
  let directionKey: string;

  if (keyPrefix === "") {
    orderingKey = ORDERING_KEY;
    directionKey = DIRECTION_KEY;
  } else {
    orderingKey = keyPrefix + capitalizeFirstLetter(ORDERING_KEY);
    directionKey = keyPrefix + capitalizeFirstLetter(DIRECTION_KEY);
  }

  return [
    (initState) => {
      const defaultValue: Record<string, string> =
        initState !== undefined
          ? {
              [orderingKey]: initState.key,
              [directionKey]: initState.direction,
            }
          : {};

      const [searchParams, setSearchParams] = useSearchParams(defaultValue);

      const state = React.useMemo(() => {
        const key = searchParams.get(orderingKey) ?? undefined;
        const direction = searchParams.get(directionKey) ?? undefined;

        return { key, direction } as Partial<OrderingState<KT>>;
      }, [searchParams]);

      const setOrdering = (value: OrderingState<KT>) => {
        const { key, direction } = value;

        if (key) searchParams.set(orderingKey, key);
        else searchParams.delete(orderingKey);

        if (direction) searchParams.set(directionKey, direction);
        else searchParams.delete(directionKey);

        setSearchParams(searchParams);
      };

      return [state, setOrdering];
    },
    new Set([orderingKey, directionKey]),
  ];
};

export const [useSummaryOrdering, summaryOrderingKeys] =
  createOrderingHook<SummaryOrderingKey>("summary");
export const [useApplicationsOrdering, applicationOrderingKeys] =
  createOrderingHook("applications");

export const useSubject = createQueryParamHook(SUBJECTS_KEY);

export const usePoolTypes = createQueryParamListHook(POOL_TYPES_KEY);
export const usePooledStatuses = createQueryParamListHook(POOLED_STATUSES_KEY);
export const useFlags = createQueryParamListHook(FLAGS_KEY);
export const useColleges = createQueryParamListHook(COLLEGES_KEY);
export const useDecisions = createQueryParamListHook(DECISIONS_KEY);

// Defines the keys which are used for the global filter state
export const GLOBAL_FILTER_KEYS = new Set([
  POOL_TYPES_KEY,
  POOLED_STATUSES_KEY,
  FLAGS_KEY,
  COLLEGES_KEY,
  SUBJECTS_KEY,
  DECISIONS_KEY,
]);
