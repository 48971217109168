import * as React from "react";

import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { PoolsideMeeting } from "api";
import PoolsideMeetingApplicantTable from "components/PoolsideMeetingApplicantTable";
import PageWithBreadcrumbs from "containers/PageWithBreadcrumbs";
import { useLoaderData } from "react-router";
import { useApplications } from "../apiHooks";

const PoolsideMeetingsDetailPage = () => {
  const poolsideMeeting = useLoaderData() as PoolsideMeeting;

  // TODO: issue-651 fetch applications related to the PMOS instead of any random applications
  const { applications, isLoading, replaceQuery } = useApplications();
  React.useEffect(() => {
    // replaceQuery({});
  });

  return (
    <PageWithBreadcrumbs>
      <Stack spacing={2} sx={{ flexGrow: 1 }}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6">{poolsideMeeting.name}</Typography>
          </Box>
        </Paper>
        <Paper sx={{ flexGrow: 1 }}>
          <Box p={2}>
            <Typography variant="subtitle2">Pooled Applicants</Typography>
          </Box>
          <Divider />
          <PoolsideMeetingApplicantTable
            isLoading={isLoading}
            applicants={applications}
          />
        </Paper>
      </Stack>
    </PageWithBreadcrumbs>
  );
};

export default PoolsideMeetingsDetailPage;
