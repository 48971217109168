import * as React from "react";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

/** Filter state selectable by component. */
export interface SummaryPageFiltersValue {
  /** Admit year. A null value indicates "any". */
  admitYear: number | null;

  /** Latest decision id. A null value indicates "any". */
  latestDecisionTypeId: string | null;
}

export interface SummaryPageFiltersProps {
  /** Possible admit years. */
  possibleAdmitYears?: number[];

  /** Possible latest decision values and descriptions. */
  possibleDecisions?: {
    value: string;
    description: string;
  }[];

  /** Selected values. */
  value?: SummaryPageFiltersValue;

  /** Is decision control disabled? */
  latestDecisionDisabled?: boolean;

  /** Callback when value changes. */
  onValueChange: (value: SummaryPageFiltersValue) => void;
}

/**
 * Filter controls for the summary page.
 */
export const SummaryPageFilters = ({
  possibleAdmitYears = [],
  possibleDecisions = [],
  onValueChange = () => null,
  latestDecisionDisabled = false,
  value = { admitYear: null, latestDecisionTypeId: null },
}: SummaryPageFiltersProps) => {
  const { admitYear, latestDecisionTypeId } = value;

  const setAdmitYear = (admitYearValue: string) => {
    if (admitYearValue === "ANY") {
      onValueChange({ ...value, admitYear: null });
    } else {
      onValueChange({ ...value, admitYear: parseFloat(admitYearValue) });
    }
  };

  const setDecision = (decisionValue: string) => {
    if (decisionValue === "ANY") {
      onValueChange({ ...value, latestDecisionTypeId: null });
    } else {
      onValueChange({ ...value, latestDecisionTypeId: decisionValue });
    }
  };

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="admit-year">Admit year</InputLabel>
          <Select
            value={admitYear === null ? "ANY" : `${admitYear}`}
            onChange={(event) => setAdmitYear(`${event.target.value}`)}
            inputProps={{
              name: "admit-year",
              id: "admit-year",
            }}
          >
            <MenuItem value="ANY">Any</MenuItem>
            {possibleAdmitYears.map((year) => (
              <MenuItem value={String(year)} key={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="decision">Latest decision</InputLabel>
          <Select
            value={
              latestDecisionDisabled || latestDecisionTypeId === null
                ? "ANY"
                : latestDecisionTypeId
            }
            disabled={latestDecisionDisabled}
            onChange={(event) => setDecision(`${event.target.value}`)}
            inputProps={{
              name: "decision",
              id: "decision",
            }}
          >
            <MenuItem value="ANY">Any</MenuItem>
            {possibleDecisions.map(({ value, description }, index) => (
              <MenuItem key={index} value={value}>
                {description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SummaryPageFilters;
