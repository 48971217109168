import {
  Chip,
  ChipProps as MUIChipProps,
  TextField,
  TextFieldProps,
  type Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { useState } from "react";

const DEFAULT_NEW_CHIP_KEYS = ["Enter"];

export type ChipProps = Omit<MUIChipProps, "label"> & {
  label: string;
};
export type ChipElement = React.ElementType<ChipProps>;

type TextFieldChipsProps = Omit<TextFieldProps, "onChange" | "value"> & {
  value: string[];
  onChange: (value: string[]) => void;
  addOnWhichKeys?: string[];
  renderChip?: (ChipElement: ChipElement, chipProps: ChipProps) => JSX.Element;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    chip: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    inputElement: {
      flex: 1,
      minWidth: "70px",
    },
  }),
);

const TextFieldChips = ({
  value: chipValues,
  onChange,
  addOnWhichKeys = DEFAULT_NEW_CHIP_KEYS,
  renderChip,
  InputProps: existingInputProps,
  inputProps: existingInputElementProps,
  ...restTextFieldProps
}: TextFieldChipsProps) => {
  const classes = useStyles();
  // the text input value not the chip values
  const [inputValue, setInputValue] = useState("");
  const [isFocussed, setIsFocussed] = useState(false);

  const clearInputValue = () => setInputValue("");

  const addChip = () => {
    const trimmedValue = inputValue.trim();

    // prevent empties and duplicate chips
    if (trimmedValue.length === 0 || chipValues.includes(trimmedValue)) {
      clearInputValue();
      return;
    }

    onChange([...chipValues, trimmedValue]);
    clearInputValue();
  };

  const deleteChip = (indexToRemove: number) => {
    const newValue = chipValues.filter((_, index) => index !== indexToRemove);
    onChange(newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isNewChipKey = addOnWhichKeys.includes(event.key);
    const isBackspaceKey = event.key === "Backspace";

    if (isNewChipKey) {
      addChip();
    } else if (isBackspaceKey) {
      // only remove an item if the input is in focus and the input is empty
      // if chip is in focus then the chips onDelete function handles it
      if (isFocussed && chipValues.length > 0 && inputValue.length === 0) {
        const lastChipIndex = chipValues.length - 1;
        deleteChip(lastChipIndex);
      }
    }
  };

  const handleInputBlur = () => {
    addChip();
    setIsFocussed(false);
  };

  return (
    <TextField
      variant="standard"
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputLabelProps={{ shrink: isFocussed || chipValues.length > 0 }}
      InputProps={{
        className: classes.inputRoot,
        onFocus: () => setIsFocussed(true),
        onBlur: handleInputBlur,
        // the Input MUI component does not support component arg but this would preferably it a ul
        // component: 'ul',
        startAdornment: chipValues.map((chipValue: string, index: number) => {
          const key = `chip-${index}`;
          const chipProps = {
            component: "li" as React.ElementType,
            key: key,
            label: chipValue,
            className: classes.chip,
            onDelete: () => deleteChip(index),
          };

          return renderChip ? (
            renderChip(Chip, chipProps)
          ) : (
            <Chip {...chipProps} />
          );
        }),
        ...existingInputProps,
      }}
      inputProps={{
        className: classes.inputElement,
        ...existingInputElementProps,
      }}
      {...restTextFieldProps}
    />
  );
};

export default TextFieldChips;
