import * as React from "react";

import { type Theme } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";
import Page from "../containers/Page";

// the style for the table toolbar
const styles = (theme: Theme) =>
  createStyles({
    middle: {
      margin: "auto",
    },
    filters: {
      display: "flex",
      flexDirection: "row",
    },
  });

const CaoDashboardContextPage: React.FunctionComponent<
  React.PropsWithChildren<WithStyles<typeof styles>>
> = ({ children, classes }) => {
  return <Page>{children}</Page>;
};

export default withStyles(styles)(CaoDashboardContextPage);
