import * as React from "react";
import { IApplication, IApplicationPatch, IFullDescription } from "../api";

import { ApplicationTableMenuItem } from "./ApplicationTableMenuItem";
import { ApplicationTableSelect } from "./ApplicationTableSelect";

/**
 * Properties for DecisionStatusSelect
 */
interface IDecisionStatusSelectProps {
  // The list of decision types
  decisionTypes: IFullDescription[] | null;
  // The application being updated
  application: IApplication;
  // The handler for when a row is updated.
  onUpdate: (patch: IApplicationPatch) => void;
}

/**
 * Component to render a status (decision) input. The component also prepares and dispatches a
 * patch update.
 */
const DecisionStatusSelect: React.FunctionComponent<
  IDecisionStatusSelectProps
> = ({ application, decisionTypes, onUpdate }) => {
  const onChange = (event: any) => {
    onUpdate({
      camsisApplicationNumber: application.camsisApplicationNumber,
      latestDecisionTypeId: `${event.target.value}`,
    });
  };

  return (
    <ApplicationTableSelect
      variant="standard"
      value={application.latestDecisionTypeId || ""}
      onChange={onChange}
    >
      {(decisionTypes || []).map((decisionType) => (
        <ApplicationTableMenuItem key={decisionType.id} value={decisionType.id}>
          {decisionType.description}
        </ApplicationTableMenuItem>
      ))}
    </ApplicationTableSelect>
  );
};

export default DecisionStatusSelect;
