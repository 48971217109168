// Redirect index page to summary page

import * as React from "react";
import { Navigate } from "react-router";

import { type Theme } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import SignInIcon from "@mui/icons-material/ExitToApp";

import { ProfileContext } from "../providers/ProfileProvider";

import LoadingIndicator from "../components/LoadingIndicator";
import Page from "../containers/Page";
import signInUrl from "../signInUrl";

const styles = (theme: Theme) =>
  createStyles({
    card: {},
    cardContent: {
      textAlign: "center",
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
  });

export const SignInNotice = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" paragraph={true}>
          You are not signed in.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          component="a"
          href={signInUrl("/")}
          id="sign-in-button"
        >
          <SignInIcon className={classes.buttonIcon} />
          Sign in
        </Button>
      </CardContent>
    </Card>
  ),
);

export const IndexPage = () => {
  const profile = React.useContext(ProfileContext);

  // No profile yet
  if (!profile) {
    return (
      <Page allowAnonymous={true}>
        <LoadingIndicator />
      </Page>
    );
  }
  // authenticated so go to summary
  if (!profile.isAnonymous) {
    return <Navigate to="/summary" />;
  }
  // need to login notice
  return (
    <Page allowAnonymous={true}>
      <SignInNotice />
    </Page>
  );
};

export default IndexPage;
