import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";

import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import ClientRoutes from "./containers/ClientRoutes";

import Snackbar from "./containers/Snackbar";
import { ApplicationsContextProvider } from "./providers/ApplicationsContextProvider";
import { DescriptionsContextProvider } from "./providers/DescriptionsContextProvider";
import { GlobalFiltersProvider } from "./providers/GlobalFiltersProvider";
import { ProfileProvider } from "./providers/ProfileProvider";
import theme from "./theme";

import { GlobalContextPageContextProvider } from "./providers/GlobalContextPageContextProvider";

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DescriptionsContextProvider>
          <ProfileProvider>
            <GlobalFiltersProvider>
              <GlobalContextPageContextProvider>
                <ApplicationsContextProvider>
                  <ClientRoutes />
                  {/* Global notification snackbar */}
                  <Snackbar />
                </ApplicationsContextProvider>
              </GlobalContextPageContextProvider>
            </GlobalFiltersProvider>
          </ProfileProvider>
        </DescriptionsContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);
