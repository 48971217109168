import * as React from "react";

import { Check as YesIcon } from "@mui/icons-material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import TimeAgo from "react-timeago";
import { IInvitationListResponse } from "../api";

import LoadingIndicator from "./LoadingIndicator";

/** helper function to format a date string using the locale */
const formatDate = (date: string) => new Date(date).toLocaleString();

export interface IProps {
  /** Whether or not data is being retrieved */
  isLoading: boolean;
  /** An invitation list response. */
  invitationListResponse: IInvitationListResponse | null;
}

/**
 * This component renders a list of invitation summaries as a table
 * as well as handling "is loading" and "no data" states.
 */
export const InvitationsPageTable = ({
  invitationListResponse,
  isLoading,
}: IProps) => {
  // No response or still fetching
  if (!invitationListResponse || isLoading) {
    return <LoadingIndicator />;
  }

  // No data in response
  if (invitationListResponse.results.length === 0) {
    return (
      <Box padding={3}>
        <Typography variant="body1" paragraph={true} align="center">
          No invitations to display or you do not have permissions to view them.
        </Typography>
      </Box>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Recipient</TableCell>
          <TableCell>Sent</TableCell>
          <TableCell>Role</TableCell>
          <TableCell align="center">Revoked</TableCell>
          <TableCell align="center">Used</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invitationListResponse.results.map((invitation, index) => (
          <TableRow key={index}>
            <TableCell>{invitation.recipient}</TableCell>
            <TableCell>
              <Tooltip title={formatDate(invitation.issuedAt)}>
                <Box>
                  <TimeAgo title="" date={invitation.issuedAt} />
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell>{invitation.roleWithContext.description}</TableCell>
            <TableCell align="center">
              {invitation.revokedAt && (
                <Tooltip title={formatDate(invitation.revokedAt)}>
                  <YesIcon />
                </Tooltip>
              )}
            </TableCell>
            <TableCell align="center">
              {invitation.usedAt && (
                <Tooltip title={formatDate(invitation.usedAt)}>
                  <YesIcon />
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InvitationsPageTable;
