import * as React from "react";

import Grid from "@mui/material/Grid";
import MUILink from "@mui/material/Link";

export interface INavigationPanelFooterLinkProps {
  /** The `href` for the anchor */
  link: string;
  /** The anchor's text */
  text: string;
}

/**
 * A component that represents a footer link (eg, linking T&Cs).
 * It is designed to be used as a child component of the `NavigationPanelFooter` component
 * and inherits it's style from that component.
 */
export const NavigationPanelFooterLink = ({
  link,
  text,
}: INavigationPanelFooterLinkProps) => (
  <Grid item>
    <MUILink href={link}>{text}</MUILink>
  </Grid>
);

export default NavigationPanelFooterLink;
