import * as React from "react";

import Box, { BoxProps } from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import Markdown from "../components/Markdown";

import { IBannerMessage } from "../api";

export interface BannerMessagesProps extends BoxProps {
  messages?: IBannerMessage[];
}

/**
 * A set of messages which are suitable for displaying as banner messages at the top of the UI.
 * Each message is represented via an object conforming to the IBannerMessage interface.
 */
export const BannerMessages = ({
  messages = [],
  ...props
}: BannerMessagesProps) => (
  <Box {...props}>
    {messages.map(({ message, backgroundColour, foregroundColour }, idx) => (
      <Box key={idx} bgcolor={backgroundColour} color={foregroundColour}>
        <Toolbar>
          <Markdown>{message}</Markdown>
        </Toolbar>
        <Divider />
      </Box>
    ))}
  </Box>
);

export default BannerMessages;
