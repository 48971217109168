import * as React from "react";

import { blue, green, grey } from "@mui/material/colors";
import type { BarDatum, BarSvgProps, ComputedDatum } from "@nivo/bar";

import type { IAssessmentScoreHistogramResponse } from "api";
import AutoSizedBarChart from "../components/AutoSizedBarChart";

export interface ComparisonHistogramProps {
  /** Histogram API response to render or null if there is not yet a response. */
  specificResponse?: IAssessmentScoreHistogramResponse | null;
  generalResponse?: IAssessmentScoreHistogramResponse | null;

  /** Props set on underlying Bar component. */
  BarProps?: BarSvgProps<BarDatum>;
}

/**
 * Histogram comparing distribution of scores between two assessmentScoreHistogram responses. The
 * responses must have the same bucket bounds for comparison. The chart is responsive and will fill
 * the parent.
 */
export const ComparisonHistogram: React.FunctionComponent<
  ComparisonHistogramProps
> = ({ BarProps, specificResponse = null, generalResponse = null }) => {
  const computedProps = React.useMemo<
    Pick<BarSvgProps<BarDatum>, "data" | "axisLeft" | "axisBottom">
  >(() => {
    // If we don't yet have all of the responses, set only the required properties on the chart to
    // show no data.
    if (specificResponse === null || generalResponse === null) {
      return { keys: [], data: [] };
    }

    // We only can show a meaningful comparison if the low bounds are equal. If they're not, show
    // no data.
    if (
      specificResponse.valueBucketLowBounds.length !==
      generalResponse.valueBucketLowBounds.length
    ) {
      return { keys: [], data: [] };
    }

    const allLowBoundsEqual = specificResponse.valueBucketLowBounds.reduce(
      (accumulator, bound, index) =>
        accumulator && bound === generalResponse.valueBucketLowBounds[index],
      true,
    );
    if (!allLowBoundsEqual) {
      return { keys: [], data: [] };
    }

    // Extract total counts and remove out-of-bounds values for the specific and general responses.

    const generalTotalCount = generalResponse.count || 0;
    const filteredGeneralCounts = generalResponse.valueBucketCounts.filter(
      ({ interval: { low, high } }) => low !== null && high !== null,
    );

    const specificTotalCount = specificResponse.count || 0;
    const filteredSpecificCounts = specificResponse.valueBucketCounts.filter(
      ({ interval: { low, high } }) => low !== null && high !== null,
    );

    // We require that the buckets passed to this component are the same in each response. We
    // have checked that above and so if we find that buckets are inconsistent there is something
    // odd in the API response and we throw an exception.
    const data = filteredGeneralCounts.map(
      ({ count: generalCount, interval: generalInterval }, index) => {
        const specificValueBucketCount = filteredSpecificCounts[index];
        if (!specificValueBucketCount) {
          throw new Error(
            "General and specific responses have different bucket counts",
          );
        }

        const { count: specificCount, interval: specificInterval } =
          specificValueBucketCount;

        if (
          specificInterval.low !== generalInterval.low ||
          specificInterval.high !== generalInterval.high
        ) {
          throw new Error(
            "General and specific responses have different bucket sizes",
          );
        }

        return {
          id: `${0.5 * ((generalInterval.low || 0) + (generalInterval.high || 0))}`,
          [CURRENT_VIEW_KEY]: specificTotalCount
            ? (100 * specificCount) / specificTotalCount
            : 0,
          [ALL_COLLEGES_KEY]: generalTotalCount
            ? (100 * generalCount) / generalTotalCount
            : 0,
        };
      },
    );

    // The left axis shows the proportion of applications as a percentage.
    const axisLeft: BarSvgProps<BarDatum>["axisLeft"] = {
      legend: "Proportion (%)",
      format: (value) => formatValue(value as number),
    };

    // The bottom axis has a legend taken from the assessment type description or "Score" if the
    // assessment type description is not returned in the response.
    const axisBottom: BarSvgProps<BarDatum>["axisBottom"] = {
      legend: generalResponse.rankingAssessmentType
        ? generalResponse.rankingAssessmentType.description
        : "Score",
    };

    return { data, axisLeft, axisBottom };
  }, [specificResponse, generalResponse]);

  return (
    <AutoSizedBarChart
      groupMode="grouped"
      keys={[CURRENT_VIEW_KEY, ALL_COLLEGES_KEY]}
      colors={({ id }: ComputedDatum<BarDatum>) =>
        PALETTE.get(`${id}`) || grey[500]
      }
      fill={PATTERN_FILL}
      defs={PATTERN_DEFS}
      tooltipFormat={(value: any) => formatValue(value) + "%"}
      {...computedProps}
      {...BarProps}
    />
  );
};

export default ComparisonHistogram;

// Utility function to format a numeric value to show at most one decimal place.
const formatValue = (value: number) => `${parseFloat(value.toFixed(1))}`;

// Keys used to display the specific and general groups.
const CURRENT_VIEW_KEY = "Current view";
const ALL_COLLEGES_KEY = "All colleges";

// Define palettes and patterns for each group.

const PALETTE = new Map([
  [CURRENT_VIEW_KEY, green[500]],
  [ALL_COLLEGES_KEY, blue[500]],
]);

const PATTERN_DEFS = [
  {
    id: "dots",
    type: "patternDots",
    background: "inherit",
    color: "rgba(0, 0, 0, 0.26)",
    size: 2,
    padding: 2,
    stagger: true,
  },
  {
    id: "lines",
    type: "patternLines",
    background: "inherit",
    color: "rgba(0, 0, 0, 0.26)",
    spacing: 8,
    lineWidth: 2,
    rotation: 45,
  },
];

const PATTERN_FILL = [
  { match: { id: CURRENT_VIEW_KEY }, id: "dots" },
  { match: { id: ALL_COLLEGES_KEY }, id: "lines" },
];
