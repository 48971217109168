/**
 * A function which will return a sign in URL which redirects back to the current page. The
 * redirect link can be overridden by the first argument. If no argument is given, we use the full
 * href to preserve query parameters and the hash.
 */
let url = (next?: string) =>
  `/accounts/login/google-oauth2?next=${encodeURIComponent(
    next || window.location.href,
  )}`;
export default url;
