// Retrieves and displays a list of invitations.
import * as React from "react";

import { Box, Button, Paper, Toolbar, Typography } from "@mui/material";

import InvitationsPageTable from "components/InvitationsPageTable";
import {
  IDescriptionsResponse,
  IRoleWithContext,
  invitationCreate,
} from "../api";
import { useInvitationList } from "../apiHooks";
import NewInvitationsDialog from "../components/NewInvitationsDialog";
import Page from "../containers/Page";
import { showMessage } from "../containers/Snackbar";
import { DescriptionsConsumer } from "../providers/DescriptionsContextProvider";
import { ProfileContext } from "../providers/ProfileProvider";

export const InnerInvitationsPage = () => {
  // state for the list of invitations retrieved from api

  const [invitationListState, invitationListFetch] = useInvitationList();

  React.useEffect(() => {
    if (
      !invitationListState.response &&
      !invitationListState.error &&
      !invitationListState.isLoading
    ) {
      invitationListFetch({ page_size: 1000 });
    }
  }, [invitationListState, invitationListFetch]);

  // report any errors fetching the list of invitations

  const invitationListError = invitationListState.error;

  React.useEffect(
    () =>
      invitationListError &&
      showMessage(
        `Error retrieving invitations: ${invitationListError.error.message}`,
      ),
    [invitationListError],
  );

  // state for controlling whether or not the dialog is visible
  const [dialogOpen, setDialogOpen] = React.useState(false);

  // handles the NewInvitationsDialog.onSend event by creating an invitation for each of the recipients
  const handleSend = React.useMemo(
    () =>
      (
        recipients: string[],
        roleWithContext: IRoleWithContext,
        message?: string,
      ) => {
        Promise.all(
          recipients.map((recipient) =>
            invitationCreate({
              recipient,
              message,
              roleWithContext,
            }),
          ),
        )
          .then((responses) =>
            showMessage(
              `${responses.length} invitation${responses.length === 1 ? "" : "s"} sent`,
            ),
          )
          .catch((error) =>
            showMessage(
              `Some invitations could not be sent: ${error.error.message}`,
            ),
          )
          .finally(() => {
            // always refresh the list..
            invitationListFetch();
            // ..and close the dialog
            setDialogOpen(false);
          });
      },
    [invitationListFetch],
  );

  const profile = React.useContext(ProfileContext);

  return (
    <DescriptionsConsumer>
      {(descriptions: IDescriptionsResponse | null) => (
        <>
          <Paper>
            <Box px={2}>
              <Toolbar disableGutters>
                <Box flexGrow={1}>
                  <Typography variant="h6">Invitations</Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setDialogOpen(true)}
                >
                  Send new invitation
                </Button>
              </Toolbar>
            </Box>
            <InvitationsPageTable
              isLoading={
                !invitationListState.response || invitationListState.isLoading
              }
              invitationListResponse={invitationListState.response}
            />
          </Paper>
          <NewInvitationsDialog
            descriptions={descriptions}
            invitableRoles={profile && profile.invitableRoles}
            open={dialogOpen}
            onCancel={() => setDialogOpen(false)}
            onSend={handleSend}
            DialogProps={{ maxWidth: "sm" }}
          />
        </>
      )}
    </DescriptionsConsumer>
  );
};

const page = () => (
  <Page>
    <InnerInvitationsPage />
  </Page>
);
export default page;
