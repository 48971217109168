// Helper hook for fetching data to populate the summary table.
import * as React from "react";
import {
  IApplicationCountsResponse,
  IAssessmentScoreHistogramResponse,
  IError,
  IGlobalFilters,
  SecondAxis,
} from "../api";
import { useApplicationCounts, useAssessmentScoreHistogram } from "../apiHooks";
import { SummaryTableRow } from "../components/SummaryTable";
import {
  ANNOTATION_TYPE_GUID_FEE_STATUS,
  ANNOTATION_TYPE_GUID_FSM,
  ANNOTATION_TYPE_GUID_GENDER,
  ANNOTATION_TYPE_GUID_HOME,
  ANNOTATION_TYPE_GUID_IMD,
  ANNOTATION_TYPE_GUID_OAC,
  ANNOTATION_TYPE_GUID_SCHOOL_TYPE,
} from "../constants/annotationTypes";
import { ASSESSMENT_TYPE_GUID_POLAR4 } from "../constants/assessmentScoreTypes";

/**
 * State returned by useSummaryTable(...).
 */
export interface SummaryTableState {
  /** Id of second axis as used in API calls. */
  secondAxis?: SecondAxis;

  /** True if an API request is in flight. */
  isLoading: boolean;

  /** Rows returned by API. */
  rows?: SummaryTableRow[];

  /**
   * Errors returned by the API. Since one update of the summary table may result in multiple API
   * calls, this may have multiple values. If there are no errors, it will not be set.
   */
  errors?: IError[];
}

/**
 * Fetch summary table state based on an application query and a second axis. If any of the inputs
 * change, the table data is re-fetched. See SummaryTableState for a description of the state.
 */
const useSummaryTable = (
  secondAxis: SecondAxis,
  query: IGlobalFilters = {},
): SummaryTableState => {
  const [polar4Histogram, fetchPolar4Histogram] = useAssessmentScoreHistogram();
  const [applicationCounts, fetchApplicationCounts] = useApplicationCounts();

  // Map responses into rows
  const rows = React.useMemo(() => {
    // If either POLAR4 or histogram responses are not present, the rows are undefined.
    if (!polar4Histogram.response || !applicationCounts.response) {
      return undefined;
    }

    // Otherwise, pass the responses to generateSummaryTableRows() to turn them into summary
    // statistics.
    return generateSummaryTableRows(
      applicationCounts.response,
      polar4Histogram.response,
    );
  }, [polar4Histogram.response, applicationCounts.response]);

  // When the query changes, re-fetch the various statistics we use.
  React.useEffect(
    () =>
      fetchPolar4Histogram({
        ...query,
        secondAxis,
        lowBounds: "0.5,1.5,2.5",
        rankingAssessmentType: ASSESSMENT_TYPE_GUID_POLAR4,
      }),
    [secondAxis, query, fetchPolar4Histogram],
  );

  React.useEffect(
    () =>
      fetchApplicationCounts({
        ...query,
        secondAxis,
      }),
    [secondAxis, query, fetchApplicationCounts],
  );

  // Collect any non-falsy error results together into the errors array.
  const errors = [polar4Histogram.error, applicationCounts.error].filter(
    (error) => Boolean(error),
  );

  return {
    rows,
    isLoading: polar4Histogram.isLoading || applicationCounts.isLoading,
    errors: errors.length > 0 ? errors : undefined,
    secondAxis: applicationCounts.response
      ? applicationCounts.response.secondAxis
      : undefined,
  };
};

/**
 * Map an application counts response and a histogram of POLAR4 quintiles into an array of rows for
 * the summary table.
 */
const generateSummaryTableRows = (
  applicationCountsResponse: IApplicationCountsResponse,
  polar4HistogramResponse: IAssessmentScoreHistogramResponse,
): SummaryTableRow[] => {
  // Find the POLAR4 quintile 1 and 2 buckets. These will be undefined if there is no matching
  // bucket in the response.
  const q1Bucket = polar4HistogramResponse.valueBucketCounts.filter(
    ({ interval: { low, high } }) =>
      (low !== null || high !== null) &&
      (low === null || low <= 1) &&
      (high === null || high > 1),
  )[0];

  const q2Bucket = polar4HistogramResponse.valueBucketCounts.filter(
    ({ interval: { low, high } }) =>
      (low !== null || high !== null) &&
      (low === null || low <= 2) &&
      (high === null || high > 2),
  )[0];

  return applicationCountsResponse.secondAxisCounts.map(
    ({ secondAxisId, secondAxisDescription, count, annotations }) => {
      // Return total count of applicants with a given annotation type,
      // regardless of annotation value.
      const annotationTotalCount = (requiredTypeId: string) =>
        (annotations.filter(({ typeId }) => typeId === requiredTypeId)[0] || {})
          .count || 0;

      // A function which will return the count of applicants with a particular annotation type
      // having one of a given list of values.
      const annotationValuesCount = (
        requiredTypeId: string,
        requiredValues: string[],
      ) =>
        annotations
          .filter(({ typeId }) => typeId === requiredTypeId)
          .reduce(
            (typeRunningCount, { values }) =>
              typeRunningCount +
              values
                .filter(({ value }) => requiredValues.indexOf(value) !== -1)
                .reduce(
                  (valueRunningCount, { count }) => valueRunningCount + count,
                  0,
                ),
            0,
          );

      // Compute the count of home applicants.
      const homeCount = annotationValuesCount(ANNOTATION_TYPE_GUID_HOME, ["Y"]);
      const schoolTypeForHomeId = `['${ANNOTATION_TYPE_GUID_HOME}', '${ANNOTATION_TYPE_GUID_SCHOOL_TYPE}']`;
      const schoolTypeCount = annotationTotalCount(schoolTypeForHomeId);

      // Compute the count of "maintained" applicants. The school type being neither 'Independent
      // School' nor 'Other'.
      const maintainedCount =
        schoolTypeCount -
        annotationValuesCount(schoolTypeForHomeId, [
          "Independent School",
          "Other",
        ]);

      // Compute the number of overseas students.
      const overseasCount = annotationValuesCount(
        ANNOTATION_TYPE_GUID_FEE_STATUS,
        ["Overseas"],
      );

      // Compute the count of female applicants. For the purposes of computing some simple gender
      // ratio, we compute the ratio of female to non-female applicants.
      const femaleCount = annotationValuesCount(ANNOTATION_TYPE_GUID_GENDER, [
        "F",
      ]);

      // Compute the number of POLAR4 quintile 1 applicants for this second axis value.
      const lpnQ1Count =
        q1Bucket &&
        q1Bucket.secondAxisCounts
          .filter(({ id }) => id === secondAxisId)
          .reduce((runningCount, { count }) => runningCount + count, 0);

      // Compute the number of POLAR4 quintile 2 applicants for this second axis value.
      const lpnQ2Count =
        q2Bucket &&
        q2Bucket.secondAxisCounts
          .filter(({ id }) => id === secondAxisId)
          .reduce((runningCount, { count }) => runningCount + count, 0);

      // Compute the count of OAC, FSM and IMS applicants
      const oacCount = annotationValuesCount(ANNOTATION_TYPE_GUID_OAC, ["Y"]);
      const fsmCount = annotationValuesCount(ANNOTATION_TYPE_GUID_FSM, ["Y"]);
      const imdCount = annotationValuesCount(ANNOTATION_TYPE_GUID_IMD, ["Y"]);

      return {
        secondAxisId: secondAxisId === null ? "None" : secondAxisId,
        secondAxisDescription:
          secondAxisId === null ? "None" : secondAxisDescription,
        count,
        homeCount,
        overseasCount,
        lpnQ1Count,
        lpnQ2Count,
        maintainedCount,
        femaleCount,
        oacCount,
        fsmCount,
        imdCount,
      };
    },
  );
};

export { useSummaryTable, generateSummaryTableRows };
