import * as React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  IBaseColumnDefinition,
  STATIC_COLUMNS_MAP,
} from "constants/columnDefinitions";
import { camelCase, kebabCase } from "lodash";
import { IApplication } from "../api";
import LoadingIndicator from "./LoadingIndicator";

export interface IProps {
  /** Whether or not data is being retrieved */
  isLoading: boolean;
  /**
   * Pooled applicants.
   *   TODO: issue-651 update type once a API response is defined
   */
  applicants: IApplication[] | null;
}

const COLUMNS = [
  STATIC_COLUMNS_MAP["ucas"],
  STATIC_COLUMNS_MAP["lastname"],
  STATIC_COLUMNS_MAP["forenames"],
  STATIC_COLUMNS_MAP["academic-plan"],
  // TODO: issue-651 add pool course group
  {
    ...STATIC_COLUMNS_MAP["college"],
    title: "Pooling College",
  },
  STATIC_COLUMNS_MAP["mature"],
  STATIC_COLUMNS_MAP["entry-year"],
  STATIC_COLUMNS_MAP["gender"],
  // TODO: issue-651 add Pool outcome column
  // TODO: issue-651 add editable columns
];

export const PoolsideMeetingApplicantTable = ({
  applicants,
  isLoading,
}: IProps) => {
  // No response or still fetching
  if (!applicants || isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {COLUMNS.map((column: IBaseColumnDefinition) => {
              return (
                <TableCell
                  id={`${kebabCase(column.title)}-column`}
                  className={`th-${column.key}`}
                  key={column.key}
                >
                  {column.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {applicants.map((application, index) => (
            <TableRow key={index}>
              {COLUMNS.map((column: IBaseColumnDefinition) => {
                return (
                  <TableCell
                    aria-labelledby={`${kebabCase(column.title)}-column`}
                    className={`td-${column.key}`}
                    key={camelCase(column.key)}
                  >
                    {column.render(application)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {applicants.length === 0 && (
        <Box py={6}>
          <Typography variant="body2" align="center">
            There are currently no pooled applicants for these subjects.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PoolsideMeetingApplicantTable;
