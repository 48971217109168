import { Link, Tooltip } from "@mui/material";
import { IAnnotation, IApplication, IAssessmentScore } from "api";
import { fromPairs } from "lodash";
import { ReactElement } from "react";
import CompactChip from "../components/CompactChip";
import { sendAnalytics } from "../utils";
import {
  ANNOTATION_INFO_LABEL_ENTRIES,
  ANNOTATION_TYPE_GUID_FEE_STATUS,
  ANNOTATION_TYPE_GUID_GENDER,
  ANNOTATION_TYPE_GUID_MATURE_STATUS,
  ANNOTATION_TYPE_GUID_SCHOOL_TYPE,
} from "./annotationTypes";
import { ASSESSMENT_TYPE_GUID_POLAR4 } from "./assessmentScoreTypes";

/**
 * Base definition for the mapping of the application data to a table column.
 */
interface IBaseColumnDefinition {
  /** a unique key */
  key: string;
  /** the column's title */
  title: string;
  /** how to render a table cell for a particular application */
  render: (application: IApplication) => any;
  /** optional secondary text as a description */
  secondaryText?: string;
}

const ANNOTATION_INFO_LABELS = fromPairs(ANNOTATION_INFO_LABEL_ENTRIES);

/**
 * Returns 'overseas' if a 'fee status' annotation with value of 'Overseas' is found.
 */
const createOverseasAnnotation = (application: IApplication) =>
  application.annotations.find(
    (annotation: IAnnotation) =>
      annotation.type.id === ANNOTATION_TYPE_GUID_FEE_STATUS &&
      annotation.value === "Overseas",
  )
    ? "overseas"
    : null;

/**
 * Returns 'maintained' if a 'school type' annotation whose values are neither
 * 'Independent School' nor 'Other' is found.
 */
const createOffaSchoolAnnotation = (application: IApplication) =>
  application.annotations.find(
    (annotation: IAnnotation) =>
      annotation.type.id === ANNOTATION_TYPE_GUID_SCHOOL_TYPE &&
      annotation.value !== "Independent School" &&
      annotation.value !== "Other",
  )
    ? "maintained"
    : null;

/**
 * Returns `polar4-${score}` if a 'polar4' assessment whose value is `${score}.00` is found.
 */
const createPolar4Annotation = (score: string) => (application: IApplication) =>
  application.assessmentScores.find(
    (assessmentScore: IAssessmentScore) =>
      assessmentScore.typeId === ASSESSMENT_TYPE_GUID_POLAR4 &&
      assessmentScore.score === `${score}.00`,
  )
    ? `polar4-${score}`
    : null;

/**
 * Selects a subset of the application's annotations to display in the info column. Additional
 * 'overseas', 'maintained', 'POLAR4 (1)', & 'POLAR4 (2)' annotations are possibly derived from
 * other annotations/data.
 */
const filterAnnotations = (application: IApplication): IAnnotation[] => {
  const annotations: IAnnotation[] = [...application.annotations];

  [
    createOverseasAnnotation,
    createOffaSchoolAnnotation,
    createPolar4Annotation("1"),
    createPolar4Annotation("2"),
  ].forEach((create) => {
    const annotation = create(application);
    if (annotation) {
      annotations.push({
        type: { id: annotation, description: "" },
        value: "Y",
      });
    }
  });

  return annotations.filter(
    (annotation: IAnnotation) =>
      ANNOTATION_INFO_LABELS[annotation.type.id] && annotation.value === "Y",
  );
};

/**
 * A custom renderer for the 'Info' column, Renders a subset of the application's annotations into
 * a set of coloured chips.
 */
const renderInfoLabels = (application: IApplication): ReactElement => {
  return (
    <>
      {filterAnnotations(application).map((annotation: IAnnotation) => (
        <Tooltip
          key={annotation.type.id}
          placement="top"
          title={ANNOTATION_INFO_LABELS[annotation.type.id].description}
        >
          <CompactChip
            size="small"
            className={`info-${annotation.type.id}`}
            label={ANNOTATION_INFO_LABELS[annotation.type.id].text}
          />
        </Tooltip>
      ))}
    </>
  );
};

/**
 * A custom renderer for the gender column
 */
const renderGender = (application: IApplication) => {
  const annotation = application.annotations.find(
    (annotation) => annotation.type.id === ANNOTATION_TYPE_GUID_GENDER,
  );
  if (annotation) {
    return annotation.value === "F"
      ? "Female"
      : annotation.value === "M"
        ? "Male"
        : "";
  }
};

const renderAnnotationValue = (
  application: IApplication,
  annotationId: string,
) => {
  const annotation = application.annotations.find(
    (annotation) => annotation.type.id === annotationId,
  );

  return annotation ? annotation.value : "";
};

const renderMature = (application: IApplication) =>
  renderAnnotationValue(application, ANNOTATION_TYPE_GUID_MATURE_STATUS);

/**
 * A custom renderer for the school type column
 */
const renderSchoolType = (application: IApplication) =>
  renderAnnotationValue(application, ANNOTATION_TYPE_GUID_SCHOOL_TYPE);

/**
 * A custom renderer for the applicant files link column
 */
const renderApplicantFiles = (application: IApplication) => {
  if (
    !application.externalResources ||
    application.externalResources.length === 0
  ) {
    return "";
  }

  const externalUrl = application.externalResources[0].externalUrl;
  // Render either a blank column or a link to the applicant files from the first external
  // resource. Use target="_blank" to make sure this opens in a new tab. We also set rel="..."
  // appropriately to avoid potential session hijack via window.opener.
  // See: https://mathiasbynens.github.io/rel-noopener/
  return (
    <Link
      href={externalUrl}
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
      onClick={() => {
        sendAnalytics("event", "view_applicant_folder", {
          view_applicant_folder_value: true,
        });
      }}
    >
      View
    </Link>
  );
};

const renderBirthdate = (application: IApplication) => {
  if (application.birthdate === null) {
    return null;
  } else {
    const date = new Date(application.birthdate);
    return new Intl.DateTimeFormat("en-GB").format(date);
  }
};

const STATIC_COLUMNS_MAP: Record<string, IBaseColumnDefinition> = {
  name: {
    key: "name",
    title: "Name",
    secondaryText: "Candidate's given name",
    render: (application: IApplication) => application.candidate.displayName,
  },
  forenames: {
    key: "forenames",
    title: "Forenames",
    secondaryText: "Candidate's forenames",
    render: (application: IApplication) => application.candidate.forenames,
  },
  lastname: {
    key: "lastname",
    title: "Last Name",
    secondaryText: "Candidate's last name",
    render: (application: IApplication) => application.candidate.lastName,
  },
  "pref-first-name": {
    key: "pref-first-name",
    title: "Preferred First Name",
    secondaryText: "Candidate's preferred first name",
    render: (application: IApplication) => application.candidate.prefFirstName,
  },
  subject: {
    key: "subject",
    title: "Subject",
    secondaryText: "Subject being applied for",
    render: (application: IApplication) => application.subjectDescription,
  },
  "academic-plan": {
    key: "academic-plan",
    title: "Academic Plan",
    secondaryText: "Academic plan code of the course being applied for",
    render: (application: IApplication) => application.subject,
  },
  tags: {
    key: "tags",
    title: "Flags",
    secondaryText: "Contextual information on application or applicant",
    render: renderInfoLabels,
  },
  college: {
    key: "college",
    title: "College",
    secondaryText: "Candidate's college preference",
    render: (application: IApplication) =>
      application.collegePreferenceDescription,
  },
  gender: {
    key: "gender",
    title: "Gender",
    secondaryText: "Candidate's gender",
    render: renderGender,
  },
  mature: {
    key: "mature",
    title: "Mature",
    secondaryText: "Candidate is mature or not",
    render: renderMature,
  },
  ucas: {
    key: "ucas",
    title: "UCAS Personal ID",
    secondaryText: "Candidate's UCAS personal ID (PID)",
    render: (application: IApplication) => application.candidate.ucasPersonalId,
  },
  usn: {
    key: "usn",
    title: "USN",
    secondaryText: "Candidate's USN",
    render: (application: IApplication) => application.candidate.camsisUsn,
  },
  application: {
    key: "application",
    title: "CamSIS Number",
    secondaryText: "Application's CamSIS number",
    render: (application: IApplication) => application.camsisApplicationNumber,
  },
  "camsis-status": {
    key: "camsis-status",
    title: "CamSIS Status",
    secondaryText: "Application's CamSIS status",
    render: (application: IApplication) => application.camsisStatusDescription,
  },
  "subject-options": {
    key: "subject-options",
    title: "Subject options",
    secondaryText: "Subject-specific options",
    render: (application: IApplication) =>
      application.subjectOptions.join(", "),
  },
  "applicant-files": {
    key: "applicant-files",
    title: "Applicant Files",
    secondaryText: "Link to digital applicant files when present",
    render: renderApplicantFiles,
  },
  "pool-type": {
    key: "pool-type",
    title: "Pool Type",
    secondaryText:
      "If the application has been pooled, specify which pool it is in",
    // We special-case the 'NONE' pool type to be an empty cell.
    render: (application: IApplication) =>
      application.poolType === "NONE"
        ? ""
        : application.poolTypeDescription || "",
  },
  "pool-status": {
    key: "pool-status",
    title: "Pooled Status",
    secondaryText:
      "More information about the current pooling status of the application",
    // We special-case the 'NONE' pool status to be an empty cell.
    render: (application: IApplication) =>
      application.poolStatus === "NONE"
        ? ""
        : application.poolStatusDescription || "",
  },
  "entry-year": {
    key: "entry-year",
    title: "Entry Year",
    secondaryText: "The applicant's entry year",
    render: (application: IApplication) => application.admitYear,
  },
  "app-year": {
    key: "app-year",
    title: "Application Year",
    secondaryText: "The applicant's application year",
    render: (application: IApplication) => application.year,
  },
  "interview-college-1": {
    key: "interview-college-1",
    title: "Pool Interview College 1",
    secondaryText: "College interviewing via Winter Pool",
    render: (application: IApplication) =>
      application.latestPoolOutcome?.interviewCollege1Description,
  },
  "interview-college-2": {
    key: "interview-college-2",
    title: "Pool Interview College 2",
    secondaryText: "College interviewing via Winter Pool",
    render: (application: IApplication) =>
      application.latestPoolOutcome?.interviewCollege2Description,
  },
  "predicted-grades": {
    key: "predicted-grades",
    title: "Predicted Grades",
    secondaryText: "The applicant's predicted grades",
    render: (application: IApplication) => application.predictedGrades,
  },
  "gcse-school-name": {
    key: "gcse-school-name",
    title: "GCSE School Name",
    secondaryText:
      "The name of the school where the applicant took their GCSEs, as declared in the SAQ",
    render: (application: IApplication) => application.gcseSchoolName,
  },
  "gcse-school-postcode": {
    key: "gcse-school-postcode",
    title: "GCSE School Postcode",
    secondaryText:
      "The postcode of the school where the applicant took their GCSEs, as declared in the SAQ",
    render: (application: IApplication) => application.gcseSchoolPostcode,
  },
  "school-name": {
    key: "school-name",
    title: "School Name",
    secondaryText:
      "Name of the applicant's school. Derived from school code of \"last educational " +
      'establishment" (apply centre) according to UCAS.',
    render: (application: IApplication) => application.schoolName,
  },
  "school-type": {
    key: "school-type",
    title: "School Type",
    secondaryText:
      "Type of the applicant's school. Derived from school type according to UCAS or CAO " +
      "override value where different.",
    render: renderSchoolType,
  },
  "home-postcode": {
    key: "home-postcode",
    title: "Home Postcode",
    secondaryText: "The applicant's home postcode",
    render: (application: IApplication) => application.homePostcode,
  },
  birthdate: {
    key: "birthdate",
    title: "Birthdate",
    secondaryText: "The applicant's date of birth",
    render: renderBirthdate,
  },
  "country-of-domicile": {
    key: "country-of-domicile",
    title: "Country of Domicile",
    secondaryText: "The applicant's declared country of permanent residence",
    render: (application: IApplication) => application.countryOfDomicile,
  },
  "college-decision": {
    key: "college-decision",
    title: "College decision (CamSIS)",
    secondaryText: "The College's decision recorded in CamSIS",
    render: (application: IApplication) =>
      application.collegeDecisionDescription,
  },
} as const;

export { ANNOTATION_INFO_LABELS, STATIC_COLUMNS_MAP, filterAnnotations };
export type { IBaseColumnDefinition };
