import * as React from "react";
import "intersection-observer"; // polyfill for intersection observer

import LoadingIndicator from "components/LoadingIndicator";
import { InView } from "react-intersection-observer";

export interface FetchMoreDataProps {
  /**
   * Whether there is further data to load.
   */
  hasMore: boolean;
  /**
   * Whether data is currently being fetched
   */
  isFetching: boolean | undefined;
  /**
   * A event triggered when more data should be loaded (when the sensor becomes visible)
   */
  onFetchMore: () => void;
}

/**
 * A specialisation of the InView component intended for use at the end of a list/table
 * to trigger the loading of ac further page of data (if it has any).
 */
export const FetchMoreData = ({
  hasMore,
  isFetching,
  onFetchMore,
}: FetchMoreDataProps) =>
  hasMore ? (
    <InView
      skip={isFetching}
      onChange={(inView) => {
        if (inView) {
          onFetchMore();
        }
      }}
    >
      <LoadingIndicator />
    </InView>
  ) : null;

export default FetchMoreData;
