// Render appropriate page content based on current route.

import * as React from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useMatches,
} from "react-router";

import { PoolsideMeeting, getPoolsideMeeting } from "api";
import PermissionedRoute from "components/PermissionedRoute";
import {
  CAO_DASHBOARD_AUTHZ_GROUPS,
  POOLSIDE_AUTHZ_GROUPS,
} from "constants/roles";
import {
  GLOBAL_FILTER_KEYS,
  applicationOrderingKeys,
  summaryOrderingKeys,
} from "hooks/useSearchParams";
import RouteMiddleware from "middlewares/RouteMiddleware";
// The various pages we know how to render.
import NoMatchPage from "pages/NoMatchPage";
import RootErrorElement from "pages/RootErrorElement";
import ApplicationsPage from "../pages/ApplicationsPage";
import CaoDashboardPage from "../pages/CaoDashboardPage";
import ChartsPage from "../pages/ChartsPage";
import IndexPage from "../pages/IndexPage";
import InvitationsAcceptPage from "../pages/InvitationsAcceptPage";
import InvitationsPage from "../pages/InvitationsPage";
import PoolsideMeetingsDetailPage from "../pages/PoolsideMeetingsDetailPage";
import PoolsideMeetingsPage from "../pages/PoolsideMeetingsPage";
import SummaryPage from "../pages/SummaryPage";

type MatchHandle = {
  /**
   * Static set of search parameters used by the route. These search parameters are moved between
   * local session storage and URL as an when they apply to the route. This is handled in the Page
   * component.
   */
  searchParams: Set<string>;
  /**
   * Callback function for generating data necessary for rendering breadcrumb component.
   *
   * @param data router match data from route loaders
   * @returns data for the route used to form breadcrumb components. See PageWithBreadcrumbs.tsx.
   */
  crumb: (data: any) => { to: string; text: string; ariaLabel?: string };
};

export type Match = ReturnType<typeof useMatches>[0] & {
  handle: MatchHandle;
};

export const ClientRoutes: React.FunctionComponent = () => {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route
        id="root"
        path=""
        element={<RouteMiddleware />}
        errorElement={<RootErrorElement />}
      >
        <Route path="" element={<IndexPage />} />
        <Route
          path="/summary"
          element={<SummaryPage />}
          handle={{
            searchParams: new Set([
              ...GLOBAL_FILTER_KEYS,
              ...summaryOrderingKeys,
            ]),
          }}
        />
        <Route
          path="/applications"
          element={<ApplicationsPage />}
          handle={{
            searchParams: new Set([
              ...GLOBAL_FILTER_KEYS,
              ...applicationOrderingKeys,
            ]),
          }}
        />
        <Route
          path="/charts"
          element={<ChartsPage />}
          handle={{
            searchParams: GLOBAL_FILTER_KEYS,
          }}
        />
        <Route path="/invitations/accept" element={<InvitationsAcceptPage />} />
        <Route path="/invitations" element={<InvitationsPage />} />
        <Route
          path="/caoDashboard"
          element={
            <PermissionedRoute anyOneOfGroups={CAO_DASHBOARD_AUTHZ_GROUPS}>
              <CaoDashboardPage />
            </PermissionedRoute>
          }
        />
        <Route
          path="/poolsideMeetings"
          element={
            <PermissionedRoute anyOneOfGroups={POOLSIDE_AUTHZ_GROUPS}>
              <Outlet />
            </PermissionedRoute>
          }
          handle={{
            crumb: () => ({
              to: "/poolsideMeetings",
              text: "Poolside Meetings",
              label: "Go to all Poolside meetings",
            }),
          }}
        >
          <Route index element={<PoolsideMeetingsPage />} />
          <Route
            path=":id"
            element={<PoolsideMeetingsDetailPage />}
            loader={({ params }) => getPoolsideMeeting(params.id!)}
            handle={{
              crumb: (data: PoolsideMeeting) => ({
                to: `/poolsideMeetings/${data.id}`,
                text: data.name,
                label: `Go to ${data.name}`,
              }),
            }}
          />
        </Route>

        <Route path="*" element={<NoMatchPage />} />
      </Route>,
    ]),
  );

  return <RouterProvider router={router} />;
};

export default ClientRoutes;
