import Typography from "@mui/material/Typography";
import * as React from "react";

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoPanel: {
      ...theme.mixins.toolbar,

      alignItems: "center",
      display: "flex",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    logo: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center",
    },

    projectStatusTag: {
      backgroundColor: "rgba(11, 121, 208, 0.2)",
      borderRadius: theme.spacing(0.5),
      color: "#0B79D0",
      fontSize: "0.75rem",
      fontWeight: 500,
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      textTransform: "uppercase",
    },
  }),
);

export interface INavigationPanelLogoProps {
  /** The logo image's source */
  logoImage: string;
  /** The logo image's alt text */
  logoImageAlt: string;
  /** A badge indicating the status of the project (eg. "Alpha") */
  projectStatusTag?: string;
}

/**
 * A component that represents the University's logo and brand.
 * It is designed to be used as a child component of the `NavigationPanel` components.
 */
export const NavigationPanelLogo = ({
  logoImage,
  logoImageAlt,
  projectStatusTag,
}: INavigationPanelLogoProps) => {
  const classes = useStyles();
  return (
    <div className={classes.logoPanel}>
      <div className={classes.logo}>
        <img src={logoImage} height="36" width="36" alt={logoImageAlt} />
      </div>
      {projectStatusTag && (
        <Typography
          variant="body1"
          color="inherit"
          className={classes.projectStatusTag}
        >
          {projectStatusTag}
        </Typography>
      )}
    </div>
  );
};

export default NavigationPanelLogo;
