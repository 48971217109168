// Dashboard for CAO operations.
import * as React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import RevokeGroupMemberships from "../components/RevokeGroupMemberships";

import DeleteProvisionalDecisions from "../components/DeleteProvisionalDecisions";
import CaoDashboardContextPage from "../containers/CaoDashboardContextPage";

const CaoDashboardPage = () => (
  <CaoDashboardContextPage>
    <h3>CAO Dashboard</h3>
    <Box mb={3}>
      <Divider />
    </Box>
    <Grid container spacing={3} direction="row">
      <Grid item md={12} lg={6}>
        <RevokeGroupMemberships />
      </Grid>
      <Grid item md={12} lg={6}>
        <DeleteProvisionalDecisions />
      </Grid>
    </Grid>
  </CaoDashboardContextPage>
);

export default CaoDashboardPage;
