import * as React from "react";

import Typography from "@mui/material/Typography";

import { type Theme } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";
import {
  useColleges,
  useDecisions,
  useFlags,
  usePoolTypes,
  usePooledStatuses,
  useSubject,
} from "hooks/useSearchParams";
import { IGlobalFilters, IMultiSelectFilters } from "../api";
import collegePreferences from "../collegePreferences.json";
import GlobalContextFilter from "../components/GlobalContextFilter";
import { Option } from "../components/MultiSelectDialog";
import MultiSelectFilter from "../components/MultiSelectFilter";
import { ANNOTATION_INFO_LABEL_ENTRIES } from "../constants/annotationTypes";
import Page from "../containers/Page";
import { useGlobalContextPageState } from "../providers/GlobalContextPageContextProvider";
import { useGlobalFiltersDispatch } from "../providers/GlobalFiltersProvider";
import { sendAnalytics } from "../utils";

// the style for the table toolbar
const styles = (theme: Theme) =>
  createStyles({
    middle: {
      margin: "auto",
    },
    filters: {
      display: "flex",
      flexDirection: "row",
    },
  });

// Convert college preferences to {key, text} form for the filter component
const collegeOptions = collegePreferences.collegePreferences.map(
  (collegePreference) => ({
    key: collegePreference.id,
    text: collegePreference.description,
  }),
);
const collegeOptionIds = collegeOptions.map((option) => option.key);

// Extract contextual flag options for the filter component
const flagOptions: Option[] = ANNOTATION_INFO_LABEL_ENTRIES.map((entry) => ({
  key: entry[0],
  text: entry[1].text,
  description: entry[1].description,
}));
flagOptions.push({
  key: "none",
  text: "None",
  description: "Application has no flags",
  disabled: true,
});
const flagOptionIds = flagOptions.map((option) => option.key);

// options for the pool type filter selection
const poolTypeOptions = [
  {
    key: "NONE",
    text: "Not pooled",
    description: "Not recorded as pooled in CamSIS",
  },
  {
    key: "SUMMER",
    text: "Summer Pool",
    description: "Recorded in CamSIS as being in the summer pool",
  },
  {
    key: "WINTER",
    text: "Winter Pool",
    description: "Recorded in CamSIS as being in the winter pool",
  },
  {
    key: "ADJUSTMENT",
    text: "Adjustment Pool",
    description: "Recorded in CamSIS as being in the adjustment pool",
  },
];
const poolTypeOptionIds = poolTypeOptions.map((option) => option.key);

// options for the pool status filter selection
const poolStatusOptions = [
  {
    key: "NONE",
    text: "No status",
    description: "Applicant has no Pooled Status",
  },
  {
    key: "NOTAG",
    text: "Not tagged",
    description: "Applicant has not been tagged by preference college",
  },
  {
    key: "TAG",
    text: "Tagged",
    description: "Preference college retains right to take back applicant",
  },
  {
    key: "DOUBLETAG",
    text: "Double Tag",
    description: "Applicant has been tagged for Joint Tripos",
  },
  {
    key: "INTERVIEW",
    text: "Interview",
    description: "Applicant has not been interviewed by preference college",
  },
  {
    key: "REASSESSMENT",
    text: "Reassessment",
    description: "Reassessment of applicant is recommended",
  },
];
const poolStatusOptionIds = poolStatusOptions.map((option) => option.key);

// options for the decision filter selection
const decisionOptions = [
  {
    key: "NONE",
    text: "No data",
    description:
      "The College has not recorded a decision in CamSIS, or the application has been withdrawn.",
  },
  {
    key: "RJDP",
    text: "Rejected",
    description: "The College has rejected the application",
  },
  {
    key: "COND",
    text: "Conditional offer",
    description: "The College has made a conditional offer",
  },
  {
    key: "UNCO",
    text: "Unconditional offer",
    description: "The College has made an unconditional offer",
  },
];
const decisionOptionIds = decisionOptions.map((option) => option.key);

/**
 * Validates and returns a partial IApplicationQuery for a filter
 */
const createQuery =
  (field: keyof IMultiSelectFilters, options: Option[]) =>
  (filter: string[] | undefined): IMultiSelectFilters => {
    // If there is no filter id, use an empty query.
    if (!filter || !filter.length) {
      return {};
    }
    // Find all matching options
    const valid: boolean = filter.every((filter) =>
      options.some((option) => option.key === filter),
    );
    // If no match, clear query.
    if (!valid) {
      console.error(
        `The filter "${filter.join(",")}" for "${field}" has not been recognized`,
      );
      return {};
    }
    return { [field]: filter };
  };

/**
 * Validates and returns a partial IApplicationQuery with collegePreferenceId
 */
const createCollegeQuery = createQuery("collegePreferenceId", collegeOptions);

/**
 * Validates and returns a partial IApplicationQuery with flagTypeId
 */
const createFlagQuery = createQuery("flagTypeId", flagOptions);

/**
 * Validates and returns a partial IApplicationQuery with poolType
 */
const createPoolTypeQuery = createQuery("poolType", poolTypeOptions);

/**
 * Validates and returns a partial IApplicationQuery with poolType
 */
const createPoolStatusQuery = createQuery("poolStatus", poolStatusOptions);

/**
 * Validates and returns a partial IApplicationQuery with poolType
 */
const createDecisionQuery = createQuery("collegeDecision", decisionOptions);

/**
 * Returns a description for a filter
 */
const filterDescription =
  (name: string, options: Option[]) =>
  (filter: string[] | undefined): string => {
    if (!filter || !filter.length) {
      return `All ${name}`;
    }
    if (filter.length > 1) {
      return `Multiple ${name}`;
    }
    const matching = (options || []).find((option) => option.key === filter[0]);
    if (!matching) {
      // TODO: issue-662 log warning once remote logging is configured
      console.warn("Unknown global filter value:", filter[0]);
      return "Unknown value";
    }
    return `${matching.text}`;
  };

/**
 * Description of selected Colleges
 */
const collegeFilterDescription = filterDescription("Colleges", collegeOptions);

/**
 * Description of selected flags
 */
const flagFilterDescription = filterDescription("Flags", flagOptions);

/**
 * Description of selected pool types
 */
const poolTypeFilterDescription = filterDescription(
  "Pool Types",
  poolTypeOptions,
);

/**
 * Description of selected pool statuses
 */
const poolStatusFilterDescription = filterDescription(
  "Pooled Statuses",
  poolStatusOptions,
);

/**
 * Description of selected pool statuses
 */
const decisionFilterDescription = filterDescription(
  "Decisions",
  decisionOptions,
);

/**
 *
 * A variant on the basic Page container which renders the AppBar with a set of drop-down controls
 * which alter a global filter query.
 */
const GlobalContextPage: React.FunctionComponent<
  React.PropsWithChildren<WithStyles<typeof styles>>
> = ({ children, classes }) => {
  // The page state: selected college and subject filter ids and possible subjects
  const state = useGlobalContextPageState();

  // Dispatch function to update the global filter query.
  const globalFiltersDispatch = useGlobalFiltersDispatch();

  // Get the possible subjects to appear in the subject drop-down.
  const possibleSubjects = state.possibleSubjects;

  // Massage the possible subjects list into a form suitable for GlobalContextFilter. Avoid
  // re-creating this on each render by using useMemo. Make sure the list of subjects is sorted by
  // description according to the current locale.
  const subjectOptions = React.useMemo(
    () =>
      // use spread operator to create new array since .sort() modifies its input
      [...(possibleSubjects || [])]
        .sort(({ description: a }, { description: b }) => a.localeCompare(b))
        .map(({ key, description }) => ({ id: key, description })),
    [possibleSubjects],
  );

  const subjectOptionIds = React.useMemo(
    () => subjectOptions.map((option) => option.id),
    [subjectOptions],
  );

  // state from query parameters
  const [poolTypeFilter, setPoolTypeFilter] = usePoolTypes({
    allowedValues: poolTypeOptionIds,
  });
  const [poolStatusFilter, setPoolStatusFilter] = usePooledStatuses({
    allowedValues: poolStatusOptionIds,
  });
  const [flagFilter, setFlagFilter] = useFlags({
    allowedValues: flagOptionIds,
  });
  const [collegeFilter, setCollegeFilter] = useColleges({
    allowedValues: collegeOptionIds,
  });
  const [subjectFilter, setSubjectFilter] = useSubject({
    allowedValues: subjectOptionIds,
  });
  const [decisionFilter, setDecisionFilter] = useDecisions({
    allowedValues: decisionOptionIds,
  });

  // When the selected subject filter changes, update the corresponding subject query.
  const subjectQuery: IGlobalFilters = React.useMemo(() => {
    // If there is no filter id, use an empty query.
    if (!subjectFilter || !possibleSubjects) {
      return {};
    }

    // Find first matching subject
    const match = possibleSubjects.find(({ key }) => key === subjectFilter);

    // If no match, clear query.
    if (!match) {
      console.error(
        `The subject filter "${subjectFilter}" has not been recognized`,
      );
      return {};
    }

    // Otherwise, update query.
    return { subjectId: match.id, subjectOption: match.option };
  }, [subjectFilter, possibleSubjects]);

  // When the filter changes, update the corresponding query.
  const collegeQuery: IMultiSelectFilters = React.useMemo(
    () => createCollegeQuery(collegeFilter),
    [collegeFilter],
  );
  const flagQuery: IMultiSelectFilters = React.useMemo(
    () => createFlagQuery(flagFilter),
    [flagFilter],
  );
  const poolTypeQuery: IMultiSelectFilters = React.useMemo(
    () => createPoolTypeQuery(poolTypeFilter),
    [poolTypeFilter],
  );
  const poolStatusQuery: IMultiSelectFilters = React.useMemo(
    () => createPoolStatusQuery(poolStatusFilter),
    [poolStatusFilter],
  );
  const decisionQuery: IMultiSelectFilters = React.useMemo(
    () => createDecisionQuery(decisionFilter),
    [decisionFilter],
  );

  // When the computed college or subject queries change, set the global query to the combined
  // value.
  React.useEffect(() => {
    if (possibleSubjects) {
      globalFiltersDispatch({
        type: "REPLACE",
        payload: {
          ...subjectQuery,
          ...collegeQuery,
          ...flagQuery,
          ...poolTypeQuery,
          ...poolStatusQuery,
          ...decisionQuery,
        },
      });
    }
  }, [
    possibleSubjects,
    subjectQuery,
    collegeQuery,
    flagQuery,
    poolTypeQuery,
    poolStatusQuery,
    decisionQuery,
    globalFiltersDispatch,
  ]);

  // Fragment used as children to the AppBar.
  const appBarChildren = (
    <>
      <Typography
        variant="h6"
        color="inherit"
        sx={{ display: { md: "block", xs: "none" } }}
      >
        {document.title}
      </Typography>
      <div className={classes.middle} />
      <div className={classes.filters}>
        <MultiSelectFilter
          title="Filter applications by pool type"
          description="Select one or more pool types to filter applications based on the pool type recorded in CamSIS"
          options={poolTypeOptions}
          initialOptionIds={
            poolTypeFilter && poolTypeFilter.length > 0
              ? poolTypeFilter
              : poolTypeOptionIds
          }
          name="pooltype"
          buttonText={poolTypeFilterDescription(poolTypeFilter)}
          onSelectionSaved={(selectedOptionIDs) => {
            const analyticsValue =
              selectedOptionIDs.length > 0
                ? selectedOptionIDs.join(",")
                : poolTypeFilterDescription(selectedOptionIDs);
            sendAnalytics("event", "change_pool_type_filter", {
              change_pool_type_filter_value: analyticsValue,
            });
            setPoolTypeFilter(selectedOptionIDs);
          }}
        />
        <MultiSelectFilter
          title="Pooled status"
          description="Select one or more options to filter applications."
          options={poolStatusOptions}
          initialOptionIds={
            poolStatusFilter && poolStatusFilter.length > 0
              ? poolStatusFilter
              : poolStatusOptionIds
          }
          name="poolstatus"
          buttonText={poolStatusFilterDescription(poolStatusFilter)}
          onSelectionSaved={(selectedOptionIDs) => {
            const analyticsValue =
              selectedOptionIDs.length > 0
                ? selectedOptionIDs.join(",")
                : poolStatusFilterDescription(selectedOptionIDs);
            sendAnalytics("event", "change_pooled_status_filter", {
              change_pooled_status_filter_value: analyticsValue,
            });
            setPoolStatusFilter(selectedOptionIDs);
          }}
        />
        <MultiSelectFilter
          title="Filter applications by flag"
          description="Select one or more flags to filter applications"
          options={flagOptions}
          initialOptionIds={
            flagFilter && flagFilter.length > 0 ? flagFilter : flagOptionIds
          }
          name="flags"
          buttonText={flagFilterDescription(flagFilter)}
          onSelectionSaved={(selectedOptionIDs) => {
            // Generate a single analytics event containing the combined selection of flags (unless
            // all flags are selected then just report the "all flags" value), and also generate an
            // analytics event for each individual flag that's selected.
            const selectedOptionIDsSet = new Set(selectedOptionIDs);
            let analyticsValue = "";
            if (selectedOptionIDs.length > 0) {
              const selectedOptions = flagOptions.filter((option) =>
                selectedOptionIDsSet.has(option.key),
              );
              selectedOptions.forEach((option) => {
                sendAnalytics("event", "set_flag_in_filter", {
                  set_flag_in_filter_value: option.text,
                });
              });
              analyticsValue = selectedOptions
                .map((option) => option.text)
                .join(",");
            } else {
              flagOptions.forEach((option) => {
                sendAnalytics("event", "set_flag_in_filter", {
                  set_flag_in_filter_value: option.text,
                });
              });
              analyticsValue = flagFilterDescription(selectedOptionIDs);
            }
            sendAnalytics("event", "change_flags_filter", {
              change_flags_filter_value: analyticsValue,
            });
            setFlagFilter(selectedOptionIDs);
          }}
        />
        <MultiSelectFilter
          title="Filter applications by college"
          description="Select one or more colleges to filter applications"
          options={collegeOptions}
          initialOptionIds={
            collegeFilter && collegeFilter.length > 0
              ? collegeFilter
              : collegeOptionIds
          }
          name="colleges"
          buttonText={collegeFilterDescription(collegeFilter)}
          onSelectionSaved={(selectedOptionIDs) => {
            sendAnalytics("event", "change_college_filter", {
              change_college_filter_value:
                collegeFilterDescription(selectedOptionIDs),
            });
            setCollegeFilter(selectedOptionIDs);
          }}
        />
        <GlobalContextFilter
          label="All Subjects"
          options={subjectOptions}
          selectedOptionId={subjectFilter}
          onOptionSelected={(selectedSubjectOptionId) => {
            const selectedOption = subjectOptions.find(
              (option) => option.id === selectedSubjectOptionId,
            );
            sendAnalytics("event", "change_subject_filter", {
              change_subject_filter_value:
                selectedOption?.description || "All Subjects",
            });

            setSubjectFilter(selectedSubjectOptionId);
          }}
        />
        <MultiSelectFilter
          title="All decisions"
          description="Select one or more options to filter applications."
          options={decisionOptions}
          initialOptionIds={
            decisionFilter && decisionFilter.length > 0
              ? decisionFilter
              : decisionOptionIds
          }
          name="collegeDecision"
          buttonText={decisionFilterDescription(decisionFilter)}
          onSelectionSaved={(selectedOptionIDs) => {
            const analyticsValue =
              selectedOptionIDs.length > 0
                ? selectedOptionIDs.join(",")
                : decisionFilterDescription(selectedOptionIDs);
            sendAnalytics("event", "change_decision_filter", {
              change_decision_filter_value: analyticsValue,
            });
            setDecisionFilter(selectedOptionIDs);
          }}
        />
      </div>
    </>
  );

  return <Page appBarChildren={appBarChildren}>{children}</Page>;
};

export default withStyles(styles)(GlobalContextPage);
