import { IProfile } from "../api";

type NullableIProfile = IProfile | null;

export const hasAnyGroup = (profile: NullableIProfile, groups: Set<string>) => {
  if (!profile || profile.groups.length === 0) {
    return false;
  }

  return profile.groups.some((g) => groups.has(g.name));
};
