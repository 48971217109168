import GenericErrorPage from "pages/GenericErrorPage";
import * as React from "react";
import { ErrorBoundary, type FallbackProps } from "react-error-boundary";
import { useLocation } from "react-router";

type ErrorValue = Error | boolean | null;

const ErrorContext = React.createContext((error: ErrorValue) => {});

// Hook to explicitly set the error state
export const useError = () => React.useContext(ErrorContext);

// Hook that reset the error boundary when the location changes.
// This is to make sure we are not stuck on an error page.
const useResetErrorBoundary = (resetFunc: () => void) => {
  const location = useLocation();
  const prevLocation = React.useRef(location.pathname);

  React.useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      resetFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};

const FallbackComponent = ({ resetErrorBoundary }: FallbackProps) => {
  useResetErrorBoundary(resetErrorBoundary);

  return <GenericErrorPage />;
};

const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = React.useState<ErrorValue>(null);

  useResetErrorBoundary(() => {
    // To prevent unnecessary re-renders, we only reset the error state if it not set.
    if (error) {
      setError(null);
    }
  });

  React.useEffect(() => {
    if (!window.addEventListener) {
      return;
    }

    const handleError = (event: ErrorEvent) => {
      setError(event.error);
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  return (
    <ErrorContext.Provider value={setError}>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        {error ? <GenericErrorPage /> : children}
      </ErrorBoundary>
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
