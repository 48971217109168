/**
 * Context for all state associated with the GlobalContextPage component.
 * To update the state, dispatch() is used
 */
import * as React from "react";
import { IDescription } from "../api";
import { useApplicationCounts } from "../apiHooks";

/** A subject filter with an optional subject option */
interface ISubject extends IDescription {
  // For subjects with options the subject ID is not unique. This is a composite key containing
  // the subject ID and option.
  key: string;
  option?: string;
}

interface ISetPossibleSubjectsAction {
  type: "SET_POSSIBLE_SUBJECTS";
  payload: ISubject[];
}

/**
 * The internal action to be used with dispatch() with additional action SET_POSSIBLE_SUBJECTS
 */
type IInnerGlobalContextPageContextAction = ISetPossibleSubjectsAction;

/** A custom type defining the state for GlobalContext */
interface IGlobalContextPageState {
  /** The possible subjects that can be filtered on - populated by fetchCounts() */
  possibleSubjects?: ISubject[];
  /** The id of the selected subject filter */
  subjectFilter?: string;
}

const INITIAL_STATE = {};

const GlobalContextPageStateContext =
  React.createContext<IGlobalContextPageState>(INITIAL_STATE);
const GlobalContextPageDispatchContext = React.createContext(
  (action: IInnerGlobalContextPageContextAction) => {},
);

/** reducer to perform any state changes */
const reducer = (
  state: IGlobalContextPageState,
  action: IInnerGlobalContextPageContextAction,
): IGlobalContextPageState => {
  switch (action.type) {
    // sets the possible subjects
    case "SET_POSSIBLE_SUBJECTS":
      return { ...state, possibleSubjects: action.payload };
  }
};

/**
 * This dictionary defines additional subject option filters. An entry is keyed on subject id and
 * has a list of the subject options to provide filters for.
 */
const SUBJECT_FILTERS_WITH_OPTIONS: { [index: string]: string[] } = {
  NSTX: ["Biological", "Physical"],
};

export const GlobalContextPageContextProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  /** Creates the GlobalContextPage state. */
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  const [{ response }, fetchCounts] = useApplicationCounts();

  // retrieve the application counts by subject
  React.useEffect(() => {
    !response &&
      fetchCounts({
        includeAnnotations: false,
        secondAxis: "subject",
      });
  }, [fetchCounts, response]);

  // set the possible subjects using the application counts by subject
  React.useEffect(() => {
    if (response) {
      const possibleSubjects: ISubject[] = [];

      response.secondAxisCounts.forEach((secondAxisCount) => {
        const subject = {
          key: secondAxisCount.secondAxisId,
          id: secondAxisCount.secondAxisId,
          description: secondAxisCount.secondAxisDescription,
        };
        possibleSubjects.push(subject);
        // if additional subject option filters are required..
        if (SUBJECT_FILTERS_WITH_OPTIONS[subject.id]) {
          // .. create these filters
          possibleSubjects.push(
            ...SUBJECT_FILTERS_WITH_OPTIONS[subject.id].map((option) => ({
              key: `${subject.id}-${option}`,
              id: subject.id,
              description: `${subject.description} (${option})`,
              option: option,
            })),
          );
        }
      });

      dispatch({ type: "SET_POSSIBLE_SUBJECTS", payload: possibleSubjects });
    }
  }, [response]);

  return (
    <GlobalContextPageStateContext.Provider value={state}>
      <GlobalContextPageDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalContextPageDispatchContext.Provider>
    </GlobalContextPageStateContext.Provider>
  );
};

export const useGlobalContextPageState = () =>
  React.useContext(GlobalContextPageStateContext);
