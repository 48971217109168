import * as React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { MultiSelectDialog, Option } from "./MultiSelectDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
}));

/**
 * Filter component properties
 */
interface IMultiSelectFilterProps {
  /** Title of the (multi-select) dialog */
  title: string;
  /** Optional description of the dialog (appears under the title) */
  description?: string;
  /** Selectable options in the dialog */
  options: Option[];
  /** IDs of initially selected options in the dialog */
  initialOptionIds: string[];
  /** Lowercase name used to set the element ids */
  name: string;
  /** Text of the button that opens the dialog */
  buttonText: string;
  /** Function called when the user requests that the currently selected options be saved and used
   * to update the filter */
  onSelectionSaved(selectedOptionIDs: string[]): void;
}

/**
 * Filter component that allows the selection of multiple items and updates page state with any
 * changes to the selection
 */
export const MultiSelectFilter: React.FunctionComponent<
  IMultiSelectFilterProps
> = ({
  title,
  description,
  options,
  initialOptionIds,
  name,
  buttonText,
  onSelectionSaved,
}) => {
  const classes = useStyles();

  const [isChooseOptionsDialogOpen, setChooseOptionsDialogOpen] =
    React.useState<boolean>(false);

  const buttonId = `${name}-button`;
  const dialogId = `select-${name}-dialog`;

  return (
    <>
      <Button
        data-role="multiSelectFilterTrigger"
        data-name={name}
        id={buttonId}
        aria-controls={dialogId}
        aria-haspopup="true"
        color="inherit"
        onClick={() => setChooseOptionsDialogOpen(true)}
        disableRipple={true}
        disableFocusRipple={true}
        className={classes.button}
      >
        {buttonText}
        <ArrowDropDownIcon />
      </Button>
      <MultiSelectDialog
        id={dialogId}
        open={isChooseOptionsDialogOpen}
        onClose={() => setChooseOptionsDialogOpen(false)}
        onCancel={() => setChooseOptionsDialogOpen(false)}
        title={title}
        description={description}
        options={options}
        onSaveSelectedKeys={(selectedOptionIDs) => {
          if (selectedOptionIDs.length === options.length) {
            selectedOptionIDs = [];
          }
          setChooseOptionsDialogOpen(false);
          onSelectionSaved(selectedOptionIDs);
        }}
        initialSelectedKeys={initialOptionIds}
      />
    </>
  );
};

export default MultiSelectFilter;
