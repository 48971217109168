import * as React from "react";

import { styled } from "@mui/material/styles";

import { Chip } from "@mui/material";

const StyledChip = styled(Chip)(() => ({
  height: 20,
  fontSize: "85%",
  color: "rgb(0, 0, 0, 0.66)",
}));

/**
 * A customisation of the MUI `<Chip>` component that makes the chip more compact if the `size`
 * property is set to `small`.
 */
// <CompactChip> is wrapped in a forwardRef component as described in
// [Caveat with refs](https://material-ui.com/guides/composition/#caveat-with-refs)
const CompactChip = React.forwardRef((props: any, ref) => (
  <StyledChip {...props} ref={ref} />
));

export default CompactChip;
