/**
 * ChooseColumnsDialog allows selection of column display and ordering for tables.
 *
 * The component manages its own UI state and communicates via a series of event handler props.
 * Users of the component do not get notified as the user re-orders and adds columns, they only get
 * notified once the user has selected the layout.
 *
 * See the individual prop descriptions for more information.
 */

import * as React from "react";

import DeleteIcon from "@mui/icons-material/Close";
import LockedIcon from "@mui/icons-material/Lock";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import { type Theme } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";

import { fromPairs, invert } from "lodash";

import { SortableList } from "./SortableList";
import { SortableListItem } from "./SortableListItem";

/** Define properties which must be present on column objects passed to this component. */
export interface IColumn {
  /** Some key which is used to identify the column. */
  key: string;

  /** Primary textual description of column. Usually a string but may be a custom component. */
  primaryText: React.ReactNode;

  /** Secondary textual description of column. Usually a string by may be a custom component. */
  secondaryText?: React.ReactNode;

  autocompleteOptionValue: string | undefined;
}

const styles = (theme: Theme) =>
  createStyles({
    dialogContent: {
      scrollBehavior: "smooth",
    },

    hidden: { display: "none" },

    addColumn: {
      minHeight: 30,
      overflowY: "hidden",
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(1),
    },

    selectedColumns: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  });

export interface IChooseColumnsDialogProps extends WithStyles<typeof styles> {
  /** Flag indicating if the dialog is shown to the user. Set on the underlying Dialog component. */
  open: boolean;

  /**
   * Array of column descriptions. See the Column interface for more details. This prop specifies
   * the order which columns appear in the "add column" interface. The order they appear in the
   * list is initially specified by initialSelectedColumnKeys.
   */
  columns?: IColumn[];

  /**
   * Keys of columns which are fixed and cannot be de-selected or moved. These will always appear
   * at the top of the column list, cannot be re-ordered and will not appear in the "add column"
   * UI.
   */
  fixedColumnKeys?: string[];

  /**
   * Initial list of selected column keys. These are shown after the fixed columns in the UI.
   * Changes to this prop will reset any user-specified ordering in the dialog.
   */
  initialSelectedColumnKeys?: string[];

  /** Function called when the user explicitly cancels the dialog box via the "Cancel" button. */
  onCancel?: () => void;

  /**
   * Function called when the user selects a new column layout. Called with an array of column keys
   * giving the new order of the selected columns. The fixed column keys do not appear in this
   * array.
   */
  onSetColumns?: (selectedKeys: string[]) => void;

  /**
   * Function called when the user closes the dialog by some other means (e.g. by clicking away).
   * Set on the underlying Dialog component.
   */
  onClose?: MuiDialogProps["onClose"];

  /** Additional props passed to the underlying Dialog component. */
  DialogProps: Omit<MuiDialogProps, "open">;
}

export const ChooseColumnsDialog = withStyles(styles)(
  ({
    classes,
    open,
    onClose = () => null,
    onCancel = () => null,
    onSetColumns = (theSelectedKeys) => null,
    columns = [],
    initialSelectedColumnKeys = [],
    fixedColumnKeys = [],
    DialogProps,
  }: IChooseColumnsDialogProps) => {
    // Create state values and setters for same.
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    // If columns prop changes, maintain columnsByKey as a map from column key to the underlying
    // Column.
    const columnsByKey = React.useMemo(
      () => fromPairs(columns.map((column) => [column.key, column])),
      [columns],
    );

    const getColumnAutocompleteValue = (column: IColumn) => {
      if (column.autocompleteOptionValue) {
        return column.autocompleteOptionValue;
      }
      if (typeof column.primaryText == "string") {
        return column.primaryText;
      }
      return column.key;
    };

    const columnAutocompleteOptionValuesByKey = Object.fromEntries(
      Object.entries(columnsByKey).map(([key, column]) => [
        key,
        getColumnAutocompleteValue(column),
      ]),
    );
    const columnKeysByAutocompleteOptionValue = invert(
      columnAutocompleteOptionValuesByKey,
    );

    // Reset the selectedKeys state if the initialSelectedColumnKeys prop changes. We don't use
    // useMemo here since we also want to manage selectedKeys elsewhere.
    React.useEffect(
      () => setSelectedKeys(initialSelectedColumnKeys),
      [initialSelectedColumnKeys],
    );

    // If selected or fixed columns array changes, update unselected array to be all columns *not* in
    // selected or fixed columns. Importantly, we respect the order of the columns prop.
    const unselectedKeys = React.useMemo(() => {
      // Form a set of all the selected or fixed keys.
      const selectedOrFixedKeys = new Set([
        ...fixedColumnKeys,
        ...selectedKeys,
      ]);

      // Update the unselected keys map with those not in the selectedOrFixedKeys set. Make sure that
      // the order matches the columns prop.
      return columns
        .filter(({ key }) => !selectedOrFixedKeys.has(key))
        .map(({ key }) => key);
    }, [fixedColumnKeys, selectedKeys, columns]);

    // Reference for Dialog Content (to be scrolled)
    const contentRef = React.createRef<HTMLElement>();
    // Whether a new column has just been added
    const [columnAdded, setColumnAdded] = React.useState(false);
    // Trigger scroll to bottom when new column added
    React.useEffect(() => {
      if (columnAdded) {
        if (contentRef.current) {
          contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
        setColumnAdded(false);
      }
    }, [columnAdded, contentRef]);

    // Convenience functions to add and remove keys to/from the selected keys array.
    const selectColumnKey = React.useMemo(
      () => (key: string) => {
        setSelectedKeys([...selectedKeys, key]);
        // Also trigger scroll to bottom
        setColumnAdded(true);
      },
      [setSelectedKeys, selectedKeys, setColumnAdded],
    );
    const deselectColumnKey = React.useMemo(
      () => (key: string) =>
        setSelectedKeys(selectedKeys.filter((k) => key !== k)),
      [selectedKeys],
    );

    const handleDialogExited = React.useMemo(
      () => () => setSelectedKeys(initialSelectedColumnKeys),
      [setSelectedKeys, initialSelectedColumnKeys],
    );
    const handleAddColumnSelect = React.useMemo(
      () => (event: any) => selectColumnKey(event),
      [selectColumnKey],
    );
    const handleSetColumnsClick = React.useMemo(
      () => () => onSetColumns(selectedKeys),
      [onSetColumns, selectedKeys],
    );

    return (
      <Dialog
        {...DialogProps}
        open={open}
        onClose={onClose}
        TransitionProps={{ onExited: handleDialogExited }}
        aria-labelledby="choose-columns-dialog-title"
        id={`choose-columns-dialog`}
      >
        <DialogTitle id="choose-columns-dialog-title">
          Customise Table Columns
        </DialogTitle>
        <DialogContent
          ref={contentRef}
          className={classes.dialogContent}
          dividers={true}
        >
          <List>
            {
              // List the fixed columns first.
              fixedColumnKeys
                .map((k) => columnsByKey[k])
                .filter((column) => Boolean(column))
                .map((column, index) => (
                  <ListItem
                    id={`fixed-column-${index}`}
                    key={index}
                    className={classes.selectedColumns}
                  >
                    <ListItemIcon>
                      <Box color="text.hint">
                        <LockedIcon />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={column.primaryText}
                      secondary={column.secondaryText}
                    />
                  </ListItem>
                ))
            }

            <SortableList
              orderedListIds={selectedKeys}
              onChange={(newOrder) => setSelectedKeys(newOrder)}
            >
              {selectedKeys
                .map((k) => columnsByKey[k])
                .filter((column) => Boolean(column))
                .map((column) => (
                  <SortableListItem
                    key={column.key}
                    id={column.key}
                    secondaryAction={
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() => deselectColumnKey(column.key)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    }
                  >
                    <ListItemText
                      primary={column.primaryText}
                      secondary={column.secondaryText}
                    />
                  </SortableListItem>
                ))}
            </SortableList>
          </List>
        </DialogContent>
        {
          /* Only show the add column UI if there are unselected columns. */
          unselectedKeys.length > 0 && (
            <DialogContent classes={{ root: classes.addColumn }}>
              <Autocomplete
                id="add-column-select"
                autoComplete
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event: any, newValue: string | null) => {
                  newValue &&
                    handleAddColumnSelect(
                      columnKeysByAutocompleteOptionValue[newValue],
                    );
                }}
                options={unselectedKeys.map(
                  (key) => columnAutocompleteOptionValuesByKey[key],
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Add column"
                  />
                )}
              />
            </DialogContent>
          )
        }
        <DialogActions>
          <Button id="cancel-button" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="set-column-button"
            color="primary"
            onClick={handleSetColumnsClick}
          >
            Set Columns
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default ChooseColumnsDialog;
