import * as React from "react";
import { useMemo } from "react";

import { debounce } from "lodash";

import { IApplication, IPoolOutcomeCreate } from "../api";
import { ApplicationTableTextField } from "./ApplicationTableTextField";

/**
 * Properties for PoolOutcomeAdmitYearInput
 */
export interface IPoolOutcomeAdmitYearInputProps {
  /** The application being updated */
  application: IApplication;
  /** The handler for when a pool outcome admit year is entered */
  onPoolOutcomeCreate: (poolOutcome: IPoolOutcomeCreate) => void;
  /** The default value of the input. */
  defaultValue: string | null;
}

/**
 * Component to render a pool outcome admission year input. The component validates the year
 * and, if valid, prepares and dispatches an `IPoolOutcomeCreate`.
 */
const PoolOutcomeAdmitYearInput: React.FunctionComponent<
  IPoolOutcomeAdmitYearInputProps
> = ({ application, onPoolOutcomeCreate, defaultValue }) => {
  // whether or not the input is in an error state
  const [error, setError] = React.useState<string | null>(null);

  // handles updates to the input on a delay
  // Note: useMemo stops the debounce from being recreated every render
  const delayedOnChange = useMemo(
    () =>
      debounce((value) => {
        const admitYear = Number(value);
        if (Number.isInteger(admitYear)) {
          setError(null);
          const poolOutcomeCreate: any = {
            status: null,
            ...(application.latestPoolOutcome || {}),
            applicationId: application.camsisApplicationNumber,
            admitYear,
          };
          onPoolOutcomeCreate(poolOutcomeCreate);
        } else {
          setError("Must be an integer");
        }
      }, 300),
    [application, onPoolOutcomeCreate],
  );

  return (
    <ApplicationTableTextField
      variant="standard"
      onChange={(event: any) => delayedOnChange(event.target.value)}
      error={!!error}
      helperText={error}
      defaultValue={defaultValue}
    />
  );
};

export default PoolOutcomeAdmitYearInput;
