import * as React from "react";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { type Theme } from "@mui/material";
import { Button, Menu, MenuItem } from "@mui/material";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";
import { kebabCase } from "lodash";

// the style for the global context filter
const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
      textTransform: "none",
    },

    edgeStart: {
      marginLeft: -theme.spacing(2),
    },

    edgeEnd: {
      marginRight: -theme.spacing(2),
    },
  });

/**
 * Defines a filter option
 */
export interface IFilterOption {
  /** the option's value */
  id: string;

  /** the display text - can be an element */
  description: React.ReactElement | string;
}

/**
 * The properties of the global context filter.
 */
interface IContextSelectProps extends WithStyles<typeof styles> {
  /** This is the text to be used for the initial filter item */
  label: string;
  /** The filter's list of options */
  options: IFilterOption[];
  /** Id of selected option */
  selectedOptionId?: string;
  /** Function called when an option is selected */
  onOptionSelected?: (optionId: string | undefined) => void;
  /**
   * Indicate if this component is at the edge of a container, if so non-visible padding will be
   * adjusted appropriately.
   */
  edge?: "start" | "end";
}

/**
 * The default filter option value
 */
const DEFAULT_SELECT_VALUE: string = " ";

/**
 * Renders a global context filter and handles the application of that filter.
 */
export const GlobalContextFilter = withStyles(styles)(
  ({
    label,
    options,
    selectedOptionId,
    onOptionSelected = () => null,
    edge,
    classes,
  }: IContextSelectProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const allOptions: IFilterOption[] = [
      { id: DEFAULT_SELECT_VALUE, description: label },
      ...options,
    ];

    const option: IFilterOption | undefined = options.find(
      (option: IFilterOption) => selectedOptionId === option.id,
    );

    const optionId = selectedOptionId ? selectedOptionId : DEFAULT_SELECT_VALUE;

    const buttonRootClass = [
      classes.button,
      edge === "start" ? classes.edgeStart : "",
      edge === "end" ? classes.edgeEnd : "",
    ].join(" ");

    const name = kebabCase(label);
    const buttonId = `${name}-button`;
    const menuId = `${name}-menu`;

    return (
      <>
        <Button
          data-role="globalContextFilter"
          data-name={name}
          data-label={label}
          id={buttonId}
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
          className={buttonRootClass}
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
          disableRipple={true}
          disableFocusRipple={true}
        >
          {option ? option.description : label}
          <ArrowDropDown />
        </Button>
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          data-role="menu"
          data-label={label}
        >
          {allOptions.map((option: IFilterOption) => (
            <MenuItem
              data-role="menuItem"
              data-text={option.description}
              data-key={option.id}
              key={option.id}
              selected={optionId === option.id}
              onClick={() => {
                onOptionSelected(
                  option.id === DEFAULT_SELECT_VALUE ? undefined : option.id,
                );
                setAnchorEl(null);
              }}
            >
              {option.description}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);

export default GlobalContextFilter;
