/**
 * The MultiSelectDialog component allows the selection of multiple options in a dialog box.
 *
 * The component manages its own UI state and communicates via a series of event handler groups.
 * Users of the component don't get notified as the user add or removes options and
 * they only get notified once the user has selected the options
 */

import * as React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { type Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { type WithStyles, createStyles, withStyles } from "@mui/styles";
import LoadingIndicator from "./LoadingIndicator";

import {
  IApplication,
  IApplicationSource,
  IApplicationSourceData,
} from "../api";

const styles = (theme: Theme) =>
  createStyles({
    title: {
      //    paddingBottom: theme.spacing(0),
    },
    content: {
      //    paddingTop: theme.spacing(1),
      //    paddingBottom: theme.spacing(1),
    },
  });

export interface IApplicationSourceDialogProps
  extends WithStyles<typeof styles> {
  // Flag indicating if the dialog is shown to the user. Set on the underlying Dialog component.
  open: boolean;
  // Function called when the user closes the dialog by some other means (e.g. by clicking away).
  // Set on the underlying Dialog component.
  onClose: DialogProps["onClose"];
  // Whether detail request is still in progress
  isLoading: boolean;
  // Application being requested or latest received with detail
  application: null | IApplication;
}

export const ApplicationSourceDialog = withStyles(styles)(
  ({
    classes,
    open,
    onClose = () => null,
    isLoading = true,
    application = null,
  }: IApplicationSourceDialogProps) => {
    if (application === null) {
      return null;
    }

    interface IRow {
      heading: string;
      values: string[];
    }

    // We need to convert the api response of the form:
    // detail.sources = [
    //   {
    //     id: 'master-import',
    //     row: [
    //       {heading: 'SubjAbbrev', value:'EC'},
    //       ...
    //     ]
    //   },
    //   ...
    // ]
    // to the form:
    // rows = [
    //   {
    //     heading: 'SubjectAbbrev',
    //     values: [ 'EC', '', ...]
    //   },
    //   ...
    // ]
    // where values is always an array of the same length as sources
    // and order of headings is preserved
    const rows = React.useMemo((): IRow[] => {
      if (!application || !application.sources || !application.sources.length) {
        return [];
      }
      const numSources = application.sources.length;
      const valuesByHeading: Map<string, string[]> = new Map();
      application.sources.forEach((source: IApplicationSource, idx) => {
        // Because there are headings with the same name we keep track of the heading count as we go
        // so that, while processing, we can name them uniquely using the form "{heading}|{index}".
        const headingCount: { [key: string]: number } = {};
        return source.row.forEach((row: IApplicationSourceData) => {
          headingCount[row.heading] =
            row.heading in headingCount ? headingCount[row.heading] + 1 : 0;
          const heading = `${row.heading}|${headingCount[row.heading]}`;
          let values = valuesByHeading.get(heading);
          if (!values) {
            values = Array(numSources).fill("");
            valuesByHeading.set(heading, values);
          }
          values[idx] = row.value;
        });
      });
      // Note that, when displaying, we remove the heading's "|{index}".
      return Array.from(valuesByHeading.entries()).map(([h, values]) => ({
        heading: h.split("|")[0],
        values,
      }));
    }, [application]);

    return (
      <Dialog
        id="application-source-dialog"
        open={open}
        onClose={onClose}
        aria-labelledby="application-source-title"
        fullWidth={false}
        maxWidth={
          application && application.sources && application.sources.length > 2
            ? "lg"
            : "md"
        }
      >
        <DialogTitle
          id="application-source-title"
          classes={{ root: classes.title }}
        >
          {`Application ${application.camsisApplicationNumber} - ${application.candidate.displayName}`}
        </DialogTitle>
        <DialogContent dividers={true} classes={{ root: classes.content }}>
          {isLoading || !application || !application.sources ? (
            <LoadingIndicator />
          ) : (
            <Table>
              {
                // Only show sources if more than one
                application.sources.length <= 1 ? null : (
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {application.sources.map((source: IApplicationSource) => (
                        <TableCell key={`head-${source.id}`}>
                          {source.id}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                )
              }
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={`row-${row.heading}`}>
                    <TableCell variant="head">{row.heading}</TableCell>
                    {row.values.map((value, idx) => (
                      <TableCell key={`cell-${row.heading}-${idx}`}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => onClose(e, "backdropClick")}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default ApplicationSourceDialog;
