import * as React from "react";

import Box from "@mui/material/Box";

import { IAssessmentScoreHistogramResponse } from "api";
import {
  Chart,
  ChartCaption,
  ChartContent,
  ChartTitle,
} from "../components/Chart";
import DecisionGroupedHistogram from "../components/DecisionGroupedHistogram";

export interface AssessmentScoreChartProps {
  title: React.ReactNode;
  response?: IAssessmentScoreHistogramResponse | null;
  isLoading?: boolean;
}

/**
 * A Chart component which shows a histogram of assessment scores broken down by decision. Wraps
 * DecisionGroupedHistogram.
 */
export const AssessmentScoreChart = ({
  title,
  isLoading = false,
  response = null,
}: AssessmentScoreChartProps) => {
  // Compute the total count of *shown* values. Only do this if the response changes.
  const totalShownCount = React.useMemo(() => {
    if (response === null) {
      return 0;
    }

    return response.valueBucketCounts
      .filter(({ interval: { low, high } }) => low !== null && high !== null)
      .reduce((accumulator, { count }) => accumulator + count, 0);
  }, [response]);

  // The "no data" condition is when we have no response or that response has a total count of
  // zero.
  const showNoDataState =
    !isLoading && (response === null || totalShownCount === 0);

  return (
    <Chart>
      <ChartTitle>{title}</ChartTitle>
      <ChartContent showLoading={isLoading} showNoDataState={showNoDataState}>
        <Box p={2} flexGrow={1}>
          <DecisionGroupedHistogram response={response} />
        </Box>
        <HistogramChartCaption response={response} />
      </ChartContent>
    </Chart>
  );
};

interface HistogramChartCaptionProps {
  response: IAssessmentScoreHistogramResponse | null;
}

/**
 * An internal component to render a caption for the current histogram.
 */
const HistogramChartCaption: React.FunctionComponent<
  HistogramChartCaptionProps
> = ({ response }) => {
  // When the response changes, compute caption message showing what data, if any, is not shown on
  // the chart.
  const captionText = React.useMemo(() => {
    // There's no text if no response.
    if (response === null) {
      return "";
    }

    // These strings will be joined with spaces to form the final caption.
    const captionTextItems = [
      `Total applications: ${response.count.toLocaleString()}.`,
    ];

    // Compute counts for no value, and out of range values.
    const noDataCount = response.valueBucketCounts
      .filter(({ interval: { low, high } }) => low === null && high === null)
      .reduce((accumulator, { count }) => accumulator + count, 0);
    const lessThanLowBoundCount = response.valueBucketCounts
      .filter(({ interval: { low, high } }) => low === null && high !== null)
      .reduce((accumulator, { count }) => accumulator + count, 0);
    const greaterThanHighBoundCount = response.valueBucketCounts
      .filter(({ interval: { low, high } }) => low !== null && high === null)
      .reduce((accumulator, { count }) => accumulator + count, 0);

    const notShownTextItems = [];
    if (noDataCount > 0) {
      notShownTextItems.push(`${noDataCount.toLocaleString()} with no score`);
    }
    if (lessThanLowBoundCount > 0) {
      notShownTextItems.push(
        `${lessThanLowBoundCount.toLocaleString()} with a score which is lower than the lowest bucket`,
      );
    }
    if (greaterThanHighBoundCount > 0) {
      notShownTextItems.push(
        `${greaterThanHighBoundCount.toLocaleString()} with a score which is higher than the highest bucket`,
      );
    }
    if (notShownTextItems.length > 0) {
      captionTextItems.push(`Not shown: ${notShownTextItems.join(", ")}.`);
    }

    return captionTextItems.join(" ");
  }, [response]);

  return <ChartCaption>{captionText}</ChartCaption>;
};

export default AssessmentScoreChart;
