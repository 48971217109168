import { isLoaderResponseError } from "api";
import { useRouteError } from "react-router";
import GenericErrorPage from "./GenericErrorPage";
import NoMatchPage from "./NoMatchPage";

const RootErrorElement = () => {
  const error = useRouteError();

  // catches errors originating from the server including requests from route loaders
  // inspired by https://reactrouter.com/6.30.0/route/error-element#throwing-responses
  if (isLoaderResponseError(error)) {
    if (error.status === 404) {
      return <NoMatchPage />;
    }
  }

  return <GenericErrorPage />;
};

export default RootErrorElement;
