import { isEqual } from "lodash";
// React hook access to API functions
import * as React from "react";
import {
  IApplication,
  IApplicationCountsQuery,
  IApplicationCountsResponse,
  IApplicationPatch,
  IApplicationQuery,
  IAssessmentScoreHistogramQuery,
  IAssessmentScoreHistogramResponse,
  IBannerMessageListResponse,
  IError,
  IInvitationListResponse,
  IInvitationQuery,
  IPoolOutcome,
  IPoolOutcomeCreate,
  applicationCountsGet,
  applicationDetailGet,
  applicationList,
  applicationPatch,
  assessmentScoreHistogramGet,
  bannerMessageList,
  invitationList,
  poolOutcomeCreate,
} from "./api";

/** Return value types for useAssessmentScoreHistogram. */

export interface UseAssessmentScoreHistogramState {
  /** Is there a request in flight? */
  isLoading: boolean;

  /** Response from the latest in-flight query. */
  response: IAssessmentScoreHistogramResponse | null;

  /** The latest in-flight query. */
  query: IAssessmentScoreHistogramQuery;

  /** Any error from the API. */
  error?: any;
}

export type UseAssessmentScoreHistogramFetch = (
  query: IAssessmentScoreHistogramQuery,
) => void;

const NO_OF_A_STAR_GCSE = "a3fa7672-b9bc-4b0a-9f34-549207e92741";
const NO_OF_A_GCSE = "231e2917-9ce1-4faa-b014-fb3d7fb88200";
const ADJUSTED_GCSE_SCORE = "a80d0374-22a0-411b-bedb-eb4909080aad";
const MAX_SCALE_FOR_GCSE_GRAPHS = 17;
const oneBucketPerPoint = Array.from(
  Array(MAX_SCALE_FOR_GCSE_GRAPHS),
  (x, i) => i - 0.5,
).join(",");

const assessmentScoreHistogramScaleOverrides: { [key: string]: string } = {
  [NO_OF_A_STAR_GCSE]: oneBucketPerPoint,
  [NO_OF_A_GCSE]: oneBucketPerPoint,
  [ADJUSTED_GCSE_SCORE]: oneBucketPerPoint,
};

/**
 * React hook which wraps assessmentScoreHistogramGet. Returns an array of two values:
 *
 *  const [{isLoading, response, query, error}, fetchResponse] = useAssessmentScoreHistogram();
 *
 *  // Request a query on component mount.
 *  React.useEffect(() => fetchResponse({ ... }), []);
 *
 * isLoading is true if there is a request in flight.
 *
 * query is the most recent query which has been requested. This is updated when fetchResponse is
 * called. When response becomes non-null, it is the response to this query.
 *
 * response is the most recent response or null if there is no current response.
 *
 * If query changes while there is a request in-flight, incoming requests which do not match the
 * query are dropped so it is "safe" to change the query fairly often.
 *
 * If fetchResponse is called when query matches the previous query and that query is still in
 * flight then no new fetch is performed.
 *
 * error is set if there was an error fetching the data.
 */
export const useAssessmentScoreHistogram = (): [
  UseAssessmentScoreHistogramState,
  UseAssessmentScoreHistogramFetch,
] => {
  const [state, setState] = React.useState<UseAssessmentScoreHistogramState>({
    isLoading: false,
    response: null,
    query: {},
  });

  const fetchResponse = React.useMemo(
    () => (query: IAssessmentScoreHistogramQuery) => {
      if (query.rankingAssessmentType) {
        const scaleOverride =
          assessmentScoreHistogramScaleOverrides[query.rankingAssessmentType];
        if (scaleOverride) {
          query = { lowBounds: scaleOverride, ...query };
        }
      }
      setState((previous: UseAssessmentScoreHistogramState) => {
        if (isEqual(previous.query, query)) {
          return previous;
        } else {
          assessmentScoreHistogramGet(query)
            .then((response) =>
              setState((previous: UseAssessmentScoreHistogramState) =>
                previous.query !== query
                  ? previous
                  : { ...previous, isLoading: false, response },
              ),
            )
            .catch((error) =>
              setState((previous: UseAssessmentScoreHistogramState) =>
                previous.query !== query
                  ? previous
                  : { ...previous, isLoading: false, error },
              ),
            );
          return { isLoading: true, response: null, query };
        }
      });
    },
    [setState],
  );

  return [state, fetchResponse];
};

export interface UseBannerMessageListState {
  isLoading: boolean;
  error?: IError;
  response?: IBannerMessageListResponse;
}

/**
 * React hook which wraps bannerMessageList. Returns a UseBannerMessageListState.
 */
export const useBannerMessageList = () => {
  const [state, setState] = React.useState<UseBannerMessageListState>({
    isLoading: false,
  });

  // On component mount, fetch message list.
  React.useEffect(() => {
    setState({ isLoading: true });
    bannerMessageList()
      .then((response) => setState({ isLoading: false, response }))
      .catch((error) => setState({ isLoading: false, error }));
  }, []);

  return state;
};

/*
 * Return value type for useInvitationList.
 */
export interface UseInvitationListState {
  /** The latest in-flight query. */
  query: IInvitationQuery;

  /** The latest in-flight en. */
  endpoint?: string;

  /** Is there a request in flight? */
  isLoading: boolean;

  /** Response from the latest in-flight query. */
  response: IInvitationListResponse | null;

  /** Any error from the API. */
  error?: any;
}

/**
 * Type describing invitationListFetch method.
 */
export type UseInvitationListFetch = (
  query?: IInvitationQuery,
  endpoint?: string,
) => void;

/**
 * React hook which wraps invitationList. Returns an array of two values:
 *
 *  const [{query, endPoint, isLoading, response, error}, fetchResponse] = useInvitationList();
 *
 *  // Request a query on component mount.
 *  React.useEffect(() => fetchResponse({ ... }), []);
 *
 * isLoading is true if there is a request in flight.
 *
 * query/endpoint is the most recent query/endpoint which has been requested. This is updated when
 * fetchResponse is called. When response becomes non-null, it is the response to this query.
 *
 * response is the most recent response or null if there is no current response.
 *
 * If query changes while there is a request in-flight, incoming requests which do not match the
 * query are dropped so it is "safe" to change the query fairly often.
 *
 * error is set if there was an error fetching the data.
 */
export const useInvitationList = (): [
  UseInvitationListState,
  UseInvitationListFetch,
] => {
  const [state, setState] = React.useState<UseInvitationListState>({
    query: {},
    isLoading: false,
    response: null,
  });

  const fetchResponse = React.useMemo(
    () =>
      (query: IInvitationQuery = {}, endpoint?: string) => {
        setState({ query, endpoint, isLoading: true, response: null });
        invitationList(query, endpoint)
          .then((response) =>
            setState((previous) =>
              previous.query !== query || previous.endpoint !== endpoint
                ? previous
                : { ...previous, isLoading: false, response },
            ),
          )
          .catch((error) =>
            setState((previous) =>
              previous.query !== query || previous.endpoint !== endpoint
                ? previous
                : { ...previous, isLoading: false, error },
            ),
          );
      },
    [setState],
  );

  return [state, fetchResponse];
};

/** Return value types for useApplicationCounts. */

export interface UseApplicationCountsState {
  /** Is there a request in flight? */
  isLoading: boolean;

  /** Response from the latest in-flight query. */
  response: IApplicationCountsResponse | null;

  /** The latest in-flight query. */
  query: IApplicationCountsQuery;

  /** Any error from the API. */
  error?: any;
}

export type UseApplicationCountsFetch = (
  query: IApplicationCountsQuery,
) => void;

/**
 * React hook which wraps applicationCountsGet. Returns an array of two values:
 *
 *  const [{isLoading, response, query, error}, fetchResponse] = useApplicationCounts();
 *
 *  // Request a query on component mount.
 *  React.useEffect(() => fetchResponse({ ... }). []);
 *
 * isLoading is true if there is a request in flight.
 *
 * query is the most recent query which has been requested. This is updated when fetchResponse is
 * called. When response becomes non-null, it is the response to this query.
 *
 * response is the most recent response or null if there is no current response.
 *
 * If query changes while there is a request in-flight, incoming requests which do not match the
 * query are dropped so it is "safe" to change the query fairly often.
 *
 * If fetchResponse is called when query matches the previous query and that query is still in
 * flight then no new fetch is performed.
 */
export const useApplicationCounts = (): [
  UseApplicationCountsState,
  UseApplicationCountsFetch,
] => {
  const [state, setState] = React.useState<UseApplicationCountsState>({
    isLoading: false,
    response: null,
    query: {},
  });

  const fetchResponse = React.useMemo(
    () => (query: IApplicationCountsQuery) => {
      setState((previous: UseApplicationCountsState) => {
        if (isEqual(previous.query, query)) {
          return previous;
        } else {
          applicationCountsGet(query)
            .then((response) =>
              setState((previous: UseApplicationCountsState) =>
                previous.query !== query
                  ? previous
                  : { ...previous, isLoading: false, response },
              ),
            )
            .catch((error) =>
              setState((previous: UseApplicationCountsState) =>
                previous.query !== query
                  ? previous
                  : { ...previous, isLoading: false, error },
              ),
            );
          return { isLoading: true, response: null, query };
        }
      });
    },
    [setState],
  );

  return [state, fetchResponse];
};

/** Return value types for useApplicationDetails. */

export interface UseApplicationDetailState {
  /** Is there a request in flight? */
  isLoading: boolean;

  /** The latest in-flight application queried or the detailed response */
  application: IApplication | null;

  /** Any error from the API. */
  error?: any;
}

export type UseApplicationDetailFetch = (application: IApplication) => void;

/**
 * React hook which wraps applicationDetailGet. Returns an array of two values:
 *
 *  const [{isLoading, application, error}, fetchResponse] = useApplicationDetail();
 *
 *  // Request a query on component mount.
 *  React.useEffect(() => fetchResponse({ ... }). []);
 *
 * isLoading is true if there is a request in flight.
 *
 * application is the most recently requested application. This is updated when fetchResponse is
 * called.
 *
 * response is the most recent response or null if there is no current response.
 *
 * If application changes while there is a request in-flight, incoming requests which do not
 * match the application are dropped so it is "safe" to change the application fairly often.
 */
export const useApplicationDetail = (): [
  UseApplicationDetailState,
  UseApplicationDetailFetch,
] => {
  const [state, setState] = React.useState<UseApplicationDetailState>({
    isLoading: false,
    application: null,
  });

  const fetchResponse = React.useMemo(
    () => (application: IApplication) => {
      setState({ isLoading: true, application });
      applicationDetailGet(application)
        .then((response) =>
          setState((previous) =>
            previous.application !== application
              ? previous
              : { isLoading: false, application: response },
          ),
        )
        .catch((error) =>
          setState((previous) =>
            previous.application !== application
              ? previous
              : { ...previous, isLoading: false, error },
          ),
        );
    },
    [setState],
  );

  return [state, fetchResponse];
};

/** Return value types for useApplications. */

interface UseApplicationState {
  // Cached list of applications corresponding to current query.
  applications: IApplication[];

  // Current query (in-flight if isLoading is true).
  query: IApplicationQuery;

  // Is there a query in-flight?
  isLoading: boolean;

  // If non-null, this URL can be used to fetch more applications which match the current query.
  nextUrl: string | null;

  // Any error arising from the last application list request.
  error?: IError;

  // Any error arising from the last application update request
  // (including creation of related objects).
  updateError?: IError;
}

/**
 * React hook which maintains a local cached list of applications and allows updating an application
 * within that list.
 */
export const useApplications = () => {
  const [
    { applications, query, isLoading, error, nextUrl, updateError },
    setState,
  ] = React.useState<UseApplicationState>({
    applications: [],
    query: {},
    isLoading: false,
    nextUrl: null,
  });

  // In replaceQuery() we have an optimisation which de-duplicates queries if they match the
  // in-flight one. We always want to fire the first query.
  const [firstRequestMade, setFirstRequestMade] =
    React.useState<boolean>(false);

  // An asynchronous function which will replace the current query with a new one.
  const replaceQuery = React.useMemo(
    () => async (newQuery: IApplicationQuery) => {
      // Optimisation: don't do anything if the new query matches the current query.
      if (firstRequestMade && isEqual(newQuery, query)) {
        return;
      }
      setFirstRequestMade(true);

      // Set the isLoading flag, clear the current application list, next URL and record the
      // in-flight query.
      setState((state) => ({
        ...state,
        query: newQuery,
        isLoading: true,
        applications: [],
        nextUrl: null,
        error: undefined,
      }));

      // Fetch the new application list for the query.
      try {
        const response = await applicationList(newQuery);

        // Only actually replace applications if query hasn't been changed in the meantime.
        setState((state) => {
          if (state.query !== newQuery) {
            return { ...state, isLoading: false };
          }
          return {
            ...state,
            applications: response.results,
            nextUrl: response.next || null,
            isLoading: false,
          };
        });
      } catch (err: unknown) {
        // Record any errors and clear isLoading flag.
        const error = err as IError;
        setState((state) => ({ ...state, isLoading: false, error }));
      }
    },
    [setState, setFirstRequestMade, query, firstRequestMade],
  );

  // An asynchronous function which will load more results if there are any to load.
  const fetchMore = React.useMemo(
    () => async () => {
      // Don't do anything if next URL is blank
      if (!nextUrl) {
        return;
      }

      // Set the isLoading flag.
      setState((state) => ({ ...state, isLoading: true, error: undefined }));

      // Fetch more applications using the next URL. We don't need to pass a query because that is
      // encoded in nextUrl.
      try {
        const response = await applicationList({}, nextUrl);

        // Only actually augment applications if next URL hasn't been changed in the meantime.
        setState((state) => {
          if (state.nextUrl !== nextUrl) {
            return { ...state, isLoading: false };
          }

          // Occasionally the API will return duplicate records for queries with complex ordering.
          // Guard against this by removing any newly returned results from the original state.
          const newAppIds = new Set(
            response.results.map((a) => a.camsisApplicationNumber),
          );

          return {
            ...state,
            applications: [
              ...state.applications.filter(
                (a) => !newAppIds.has(a.camsisApplicationNumber),
              ),
              ...response.results,
            ],
            nextUrl: response.next || null,
            isLoading: false,
          };
        });
      } catch (err: unknown) {
        // Record any errors and clear isLoading flag.
        const error = err as IError;
        setState((state) => ({ ...state, isLoading: false, error }));
      }
    },
    [setState, nextUrl],
  );

  // An asynchronous function which will patch an application and, if it is in the current
  // application list, update the cached application in the state as well.
  const patch = React.useMemo(
    () => async (patch: IApplicationPatch) => {
      // There must be a primary key
      if (!patch.camsisApplicationNumber) {
        throw new Error("No application id provided in patch");
      }

      // Find the previous record of this application in the cache. A patch *must* refer to a
      // previously fetched application.
      const previousApplication = applications.filter(
        (a) => a.camsisApplicationNumber === patch.camsisApplicationNumber,
      )[0];
      if (!previousApplication) {
        throw new Error("Cannot patch application which is not in cache");
      }

      // Patch the application.
      setState((state) => ({ ...state, updateError: undefined }));
      try {
        const application = await applicationPatch(
          previousApplication.url,
          patch,
        );

        // Update application list replacing updated application if present.
        setState(({ applications, ...state }) => {
          const newApplications = applications.map((a) => {
            if (
              a.camsisApplicationNumber === application.camsisApplicationNumber
            ) {
              return { ...a, ...application };
            }
            return a;
          });
          return { applications: newApplications, ...state };
        });

        // Return the new application
        return application;
      } catch (error: unknown) {
        const updateError = error as IError;
        setState((state) => ({ ...state, updateError }));
        throw new Error("Error patching application");
      }
    },
    [setState, applications],
  );

  // An asynchronous function which will create a pool outcome for an application and, if it is in
  // the current application list, update the cached application in the state.
  const createPoolOutcome = React.useMemo(
    () => async (poolOutcome: IPoolOutcomeCreate) => {
      // Ensure that the application is in the cache.
      if (
        !applications.find(
          (a) => a.camsisApplicationNumber === poolOutcome.applicationId,
        )
      ) {
        throw new Error(
          "Cannot create pool outcome for an application not in cache",
        );
      }

      // create the pool outcome
      setState((state) => ({ ...state, createError: undefined }));
      try {
        const createPoolOutcome: IPoolOutcome =
          await poolOutcomeCreate(poolOutcome);
        setState(({ applications, ...state }) => {
          const newApplications = applications.map((a) => {
            if (a.camsisApplicationNumber === poolOutcome.applicationId) {
              return { ...a, latestPoolOutcome: createPoolOutcome };
            }
            return a;
          });
          return { applications: newApplications, ...state };
        });

        // Return the new pool outcome
        return createPoolOutcome;
      } catch (createError) {
        setState((state) => ({ ...state, createError }));
        throw new Error("Error creating pool outcome");
      }
    },
    [setState, applications],
  );

  const hasMore = !!nextUrl;

  return {
    applications,
    query,
    replaceQuery,
    fetchMore,
    error,
    isLoading,
    nextUrl,
    hasMore,
    updateError,
    patch,
    createPoolOutcome,
  };
};
