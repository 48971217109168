import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Bar } from "@nivo/bar";
import type { BarDatum, BarSvgProps } from "@nivo/bar";
import type { Theme } from "@nivo/core";
import { AutoSizer } from "react-virtualized";
import { NoOutlineWidthTheme } from "theme";

/**
 * An auto-sizing variant of nivo's Bar component which is themed to fit in with the rest of the
 * application.
 *
 * We cannot use ResponsiveBar since that has a number of bugs in its implementation. For example.
 * it will quite often grow without bound when placed in a flex box.
 */
export const AutoSizedBarChart: React.FunctionComponent<
  Pick<BarSvgProps<BarDatum>, "data"> &
    Partial<BarSvgProps<BarDatum>> & { tooltipFormat?: (value: any) => string }
> = ({ axisBottom, axisLeft, margin, ...BarProps }) => {
  const theme = useTheme();
  const nivoTheme = useNivoTheme();

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Bar
          animate={false}
          theme={nivoTheme}
          margin={{
            top: parseInt(theme.spacing(1)),
            bottom: parseInt(theme.spacing(6)),
            left: parseInt(theme.spacing(7)),
            right: parseInt(theme.spacing(17)),
            ...margin,
          }}
          legends={[
            {
              anchor: "bottom-right",
              dataFrom: "keys",
              direction: "column",
              itemDirection: "right-to-left",
              itemHeight: parseInt(theme.spacing(2)),
              itemWidth: parseInt(theme.spacing(15)),
              itemsSpacing: parseInt(theme.spacing(1)),
              justify: true,
              symbolSize: parseInt(theme.spacing(2)),
              translateX: parseInt(theme.spacing(17)),
            },
          ]}
          axisBottom={{
            legendPosition: "middle",
            legendOffset: parseInt(theme.spacing(5)),
            ...axisBottom,
          }}
          axisLeft={{
            legendPosition: "middle",
            legendOffset: -theme.spacing(6),
            ...axisLeft,
          }}
          enableLabel={false}
          {...BarProps}
          width={width}
          height={height}
        />
      )}
    </AutoSizer>
  );
};

export default AutoSizedBarChart;

/** Customised nivo theme for charts. */
export const useNivoTheme = (): Theme => {
  const theme = useTheme<NoOutlineWidthTheme>();

  const captionText = {
    ...theme.typography.caption,
    fill: theme.palette.text.secondary,
  };
  const body2Text = {
    ...theme.typography.body2,
    fill: theme.palette.text.primary,
  };

  return {
    axis: {
      ticks: { text: captionText },
      legend: { text: captionText },
    },
    labels: { text: captionText },
    legends: { text: body2Text },
    dots: { text: body2Text },
    tooltip: { container: body2Text },
  };
};
