/**
 * Custom University of Cambridge material UI theme
 */

// Since the ordering of properties has some meaning to users editing this file even if they are of
// no interest to the code, we disable the alphabetical sort check.
//
// tslint:disable:object-literal-sort-keys

import { createTheme } from "@mui/material/styles";
import type { Theme } from "@mui/material/styles";

export const drawerWidth = 30 * 8;

// Recursively omit K from T
type DeepOmit<T, K extends keyof any> = {
  [P in keyof T]: T[P] extends string | number
    ? T[P]
    : Omit<DeepOmit<T[P], K>, K>;
};

export type NoOutlineWidthTheme = DeepOmit<Theme, "outlineWidth">;

const isNoOutlineWidthTheme = (obj: any): obj is NoOutlineWidthTheme => {
  /**
   * Quick-and-dirty DFS for k in obj
   *
   * @param obj
   */
  const dfs = (obj: any, k: string): any | null => {
    for (const [_k, v] of Object.entries(obj)) {
      if (_k === k) {
        return v;
      } else {
        switch (typeof v) {
          case "string":
          case "number":
            break;
          default:
            // eslint-disable-next-line no-case-declarations
            const value = dfs(v, k);

            if (value !== null) {
              return value;
            }
        }
      }
    }

    return null;
  };

  // Check if outlineWidth is in the object (essentially, check if it's in the CSS for the theme).
  // If it isn't, the result will be null, meaning this IS a theme with no outline width.
  //
  // (commenting because double negatives are always a breeding ground for bugs...)
  return dfs(obj, "outlineWidth") === null;
};

const _theme = createTheme({
  palette: {
    primary: {
      light: "#5fa7ff",
      main: "#0b79d0",
      dark: "#004e9e",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffbc45",
      main: "#ff8b00",
      dark: "#c55c00",
      contrastText: "#000",
    },
  },
  // https://mui.com/material-ui/migration/v5-component-changes/#breakpoint-sizes
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    // https://mui.com/material-ui/migration/v5-component-changes/#%E2%9C%85-update-default-underline-prop
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
  },
});

if (!isNoOutlineWidthTheme(_theme)) {
  throw new Error("Theme contains outlineWidth (unsupported for @nivo/bar.");
}

const theme: NoOutlineWidthTheme = _theme;

export default theme;
