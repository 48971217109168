/** Context for a global filter. */
import * as React from "react";
import { IGlobalFilters } from "../api";

/** The action to be used with dispatch() */
export interface IGlobalFiltersAction {
  type: "REPLACE";

  payload: IGlobalFilters;
}

export const GlobalFiltersStateContext = React.createContext<IGlobalFilters>(
  {},
);
export const GlobalFiltersDispatchContext = React.createContext(
  (action: IGlobalFiltersAction) => {},
);

/** reducer to perform any state changes */
const reducer = (
  state: IGlobalFilters,
  { type, payload }: IGlobalFiltersAction,
) => {
  switch (type) {
    case "REPLACE":
      return payload;
    default:
      throw new Error(`Unknown action: ${type}`);
  }
};

export const GlobalFiltersProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {});

  return (
    <GlobalFiltersStateContext.Provider value={state}>
      <GlobalFiltersDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalFiltersDispatchContext.Provider>
    </GlobalFiltersStateContext.Provider>
  );
};

export const useGlobalFiltersState = () =>
  React.useContext(GlobalFiltersStateContext);
export const useGlobalFiltersDispatch = () =>
  React.useContext(GlobalFiltersDispatchContext);
