import * as React from "react";

import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink, useMatches } from "react-router";
import { Match } from "./ClientRoutes";
import Page, { IPageProps as PageProps } from "./Page";

type Props = PageProps & {};

const PageWithBreadcrumbs: React.FunctionComponent<
  React.PropsWithChildren<Omit<Props, "classes">>
> = ({ children, ...rest }) => {
  const matches = useMatches() as Match[];

  const crumbs = React.useMemo(
    () =>
      matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle?.crumb(match.data)),
    [matches],
  );

  const breadcrumbs = React.useMemo(() => {
    return crumbs.map((crumb, index) => {
      // render text if last crumb
      if (index === crumbs.length - 1)
        return <Typography key={index}>{crumb.text}</Typography>;

      return (
        <Link
          key={index}
          component={RouterLink}
          to={crumb.to}
          aria-label={crumb.ariaLabel}
        >
          {crumb.text}
        </Link>
      );
    });
  }, [crumbs]);

  return (
    <Page {...rest}>
      {crumbs.length > 0 && (
        <Box pb={2}>
          <Breadcrumbs aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>
        </Box>
      )}
      {children}
    </Page>
  );
};

export default PageWithBreadcrumbs;
