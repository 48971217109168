// Summary statistics for applications.
import * as React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";

import { SecondAxis } from "../api";
import { useApplicationCounts } from "../apiHooks";
import SummaryPageFilters, {
  SummaryPageFiltersValue,
} from "../components/SummaryPageFilters";
import ApplicationSummaryTable from "../containers/ApplicationSummaryTable";
import GlobalContextPage from "../containers/GlobalContextPage";
import { useGlobalFiltersState } from "../providers/GlobalFiltersProvider";

const SummaryPage = () => {
  const classes = useStyles();

  const globalQuery = useGlobalFiltersState();

  // Fetch possible admit years from the API. There is some special caching behaviour designed to
  // stop the select repeatedly gaining and losing values. See the documentation for
  // useAdmitYears() below.
  const possibleAdmitYears = useAdmitYears();

  const [filter, setFilter] = React.useState<SummaryPageFiltersValue>({
    admitYear: null,
    latestDecisionTypeId: null,
  });

  // If the current admit year ever becomes impossible, reset back to "any".
  React.useEffect(() => {
    if (
      filter.admitYear !== null &&
      possibleAdmitYears.indexOf(filter.admitYear) === -1
    ) {
      setFilter((prev) => ({ ...prev, admitYear: null }));
    }
  }, [filter.admitYear, setFilter, possibleAdmitYears]);

  // Use useMemo to make sure our query only changes if a dependent setting changes. Otherwise
  // re-renders would cause API re-fetches.
  const query = React.useMemo(
    () => ({
      admitYear: filter.admitYear !== null ? filter.admitYear : undefined,
      latestDecisionTypeId:
        filter.latestDecisionTypeId !== null
          ? filter.latestDecisionTypeId.split(",")
          : undefined,

      // The global query trumps everything. Most notably, it may set latestDecisionTypeId if the
      // pool is selected.
      ...globalQuery,
    }),
    [globalQuery, filter],
  );

  // Index of currently displayed tab.
  const [tabIndex, setTabIndex] = React.useState(0);

  // Whether the "latest decision" control is disabled depends on whether the global query has
  // overridden the decision. It will do this if, for example, the pool is selected.
  const decisionDisabled =
    typeof globalQuery.latestDecisionTypeId !== "undefined";

  // The second axis we render depends on the current tab index.
  const tabSecondAxes: SecondAxis[] = [
    "subject",
    "collegePreference",
    "latestDecision",
    "admitYear",
  ];
  const secondAxis = tabSecondAxes[tabIndex] as SecondAxis;

  return (
    <GlobalContextPage>
      <Grid spacing={2} container justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Paper>
            <Box p={2}>
              <SummaryPageFilters
                value={filter}
                onValueChange={setFilter}
                latestDecisionDisabled={decisionDisabled}
                possibleAdmitYears={possibleAdmitYears}
                possibleDecisions={[
                  // For the moment we hard-code these values to replicate functionality from //
                  // Downing's prototype where one could only see summaries for offers.  It is likely
                  // this will probably change to having behaviour similar to // admitYear at some
                  // point.
                  { value: "OFFER", description: "Definite offer" },
                  {
                    value: "OFFER,CONDITIONAL",
                    description: "Definite or possible offer",
                  },
                ]}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            {
              // We use tabs to let the user select if they want to see stats broken down by college,
              // subject or decision. The original prototype application attempted to guess based on
              // whether a college was selected but that "spooky action at a distance" was causing the
              // complexity of the code to sky-rocket.
            }
            <Tabs
              value={tabIndex}
              onChange={(event, value) => setTabIndex(value)}
              indicatorColor="primary"
              textColor="primary"
              centered
              id="summary-tabs"
              variant="standard"
              scrollButtons="auto"
            >
              {/* Add ids so that it is easier for Selenium tests to find the tabs. */}
              <Tab label="Subjects" id="subject-summary-tab" />
              <Tab label="Colleges" id="college-summary-tab" />
              <Tab label="Decisions" id="decisions-summary-tab" />
              <Tab label="Admit year" id="admit-year-summary-tab" />
            </Tabs>

            <Divider />

            <div className={classes.tableContainer}>
              <ApplicationSummaryTable
                query={query}
                secondAxis={secondAxis}
                aria-controls="summary-tabs"
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </GlobalContextPage>
  );
};

const useStyles = makeStyles({
  tableContainer: {
    overflowX: "auto",
  },
});

export default SummaryPage;

// (ab)use the useApplicationCounts hook to retrieve possible admit years for the current global
// query. The admit years are returned as strings in ascending *numeric* order. While loading, the
// return value is whatever the previously fetched array was. This is to avoid de-populating the
// admit year drop-down and re-populating it again needlessly. This hook's behaviour is therefore
// tuned very much to this component and shouldn't be viewed as "generic".
const useAdmitYears = () => {
  const [cachedArray, setCachedArray] = React.useState<number[]>([]);
  const [{ response }, fetchCounts] = useApplicationCounts();
  const globalQuery = useGlobalFiltersState();

  // Fetch possible admit years given the global query.
  React.useEffect(
    () => fetchCounts({ ...globalQuery, secondAxis: "admitYear" }),
    [globalQuery, fetchCounts],
  );

  // When we get a response back from the API, updated the cached array.
  React.useEffect(() => {
    // If we don't have an API response, don't change anything.
    if (!response) {
      return;
    }

    setCachedArray(
      response.secondAxisCounts
        .map(({ secondAxisId }) => parseFloat(secondAxisId))
        .sort(),
    );
  }, [response, setCachedArray]);

  return cachedArray;
};
