/**
 * Context for the Descriptions endpoint.
 */
import * as React from "react";
import { IDescriptionsResponse, descriptionsGet } from "../api";

export const DescriptionsContext =
  React.createContext<IDescriptionsResponse | null>(null);
export const DescriptionsConsumer = DescriptionsContext.Consumer;

export const DescriptionsContextProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [descriptions, setDescriptions] =
    React.useState<IDescriptionsResponse | null>(null);

  React.useEffect(() => {
    descriptionsGet().then((descriptions: IDescriptionsResponse | null) =>
      setDescriptions(descriptions),
    );
  }, []);
  return (
    <DescriptionsContext.Provider value={descriptions}>
      {children}
    </DescriptionsContext.Provider>
  );
};
