import * as React from "react";

import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";

// We support all the AppBar properties as well as an "onMenuClick" event handler.
export interface IProps extends AppBarProps {
  /** Event handler for click on menu "hamburger" button. */
  onMenuClick?: React.MouseEventHandler;
}

/**
 * An application bar which shows the current document's title. For small screens, there is a
 * "hamburger" menu button displayed. If the component has any children, they are rendered within
 * the central Toolbar component in place of the document title.
 */
export const AppBar = ({ children, onMenuClick, ...otherProps }: IProps) => (
  <MuiAppBar {...otherProps}>
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={onMenuClick}
        edge="start"
        size="large"
        sx={{ display: { lg: "none", xs: "block" } }}
      >
        <MenuIcon />
      </IconButton>
      {children || (
        <Typography variant="h6" color="inherit">
          {document.title}
        </Typography>
      )}
    </Toolbar>
  </MuiAppBar>
);

export default AppBar;
